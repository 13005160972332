import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";

export const UPDATE_AGENT_MAPPING_LIST = "UPDATE_AGENT_MAPPING_LIST";
export const UPDATE_AGENT_MAPPING = "UPDATE_AGENT_MAPPING";

const PAGE_SIZE = 10;

export const getAgentMappingList = (page, formData = {}) => {
  return (dispatch) => {
    const url = "/kira/third-party-agents/list";
    return instance({
      url,
      method: "GET",
      params: {
        ...formData.filters,
        page,
        size: PAGE_SIZE,
      },
    })
      .then((response) => {
        const { data: list, total } = response.data;
        dispatch({
          type: UPDATE_AGENT_MAPPING_LIST,
          payload: {
            list,
            totalPages: Math.ceil(total / PAGE_SIZE),
            totalCount: total,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const createAgentMapping = (data) => {
  return (dispatch) => {
    const url = "/kira/third-party-agents/add-user-agent-mapping";
    return instance({
      url,
      method: "POST",
      data: {
        user_agent_mappings: [{ ...data.form }],
      },
    })
      .then(() => {
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Agent Added successfully!",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const updateAgentMapping = (data) => {
  return (dispatch) => {
    const url = "/kira/third-party-agents/update-user-agent-mapping-single";
    return instance({
      url,
      method: "PUT",
      data: {
        ...data.form,
      },
    })
      .then((response) => {
        const agent = response.data;
        dispatch({
          type: UPDATE_AGENT_MAPPING,
          payload: {
            ...agent,
          },
        });
        dispatch({
          type: Constants.API_SUCCESS,
          errMsg: "Agent Updated successfully!",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getProviderList = () => {
  return () => {
    return instance({
      url: "/kira/providers-list",
      method: "GET",
    })
      .then((response) => {
        return response?.data?.providers;
      })
      .catch(() => {
        return [];
      });
  };
};
