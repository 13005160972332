import {
  UPDATE_AGENT_MAPPING_LIST,
  UPDATE_AGENT_MAPPING,
} from "../actions/callingAgentMapping.actions";

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_AGENT_MAPPING_LIST:
      return {
        ...state,
        agentMappings: {
          list: action.payload.list,
          totalPages: action.payload.totalPages,
          totalCount: action.payload.totalCount,
        },
      };
    case UPDATE_AGENT_MAPPING:
      return {
        ...state,
        agentMappings: {
          ...state.agentMappings,
          list: state.agentMappings.list.map((agent) =>
            agent.agentId === action.payload.agentId
              ? { ...agent, ...action.payload }
              : agent
          ),
        },
      };
  }
};

export default reducer;
