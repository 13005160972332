import {
  AccountBox,
  AccountCircle,
  AddToHomeScreen,
  Assessment,
  AttachMoney,
  Build,
  CalendarToday,
  Dashboard,
  Description,
  DonutLarge,
  EventNote,
  FindInPage,
  Fingerprint,
  Games,
  Group,
  GroupWork,
  Home,
  InsertDriveFile,
  ListAlt,
  LocalParking,
  LocationCity,
  MergeType,
  Money,
  Payment,
  PersonPinCircle,
  Phone,
  PlayForWork,
  PostAdd,
  SwapHorizontalCircle,
  TableChart,
  TrackChanges,
  TrendingUp,
  Web,
  MapRounded,
} from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/List";
import Constants from "./CommonConstants";

const ROUTES = {
  AD_PRODUCT_CONFIG: "/ad-product-config",
  AGENT_PERFORMANCE_REPORT: "/agent-performance-report",
  AGENT_PERFORMANCE: "/agent/performance",
  CALIING_AGENT_MAPPING: "/calling-agent-mapping",
  AUDIENCE_MAXIMIZER: "/audience-maximizer",
  BROKER_DASHBOARD: "/broker-dashboard",
  BUILDER_DASHBOARD: "/builder-dashboard",
  CALL_DETAIL: "/call-detail",
  CAMPAIGN_MODULE: "/campaign-module",
  CHURN_SCHEDULER: "/churn-scheduler",
  COMMON_MATRICES: "/common-matrices",
  DIGILITE_VIDEOTOUR_MAPPING: "/digilite-videotour-mapping",
  EVENT_CITY_MAPPING: "/event-city-mapping",
  DISCOUNT_MATRIX: "/discount-matrix",
  EVENTS: "/events",
  FLAT_PANEL: "/flat-panel",
  HOME: "/home",
  INCENTIVE_CALCULATION: "/incentive-calculation",
  INCENTIVE_PAYOUT: "/incentive-payout",
  INVOICE_REPORT: "/invoice-report",
  LEAD_FEEDBACK: "/lead-feedback",
  LIVE_VIEW: "/live-view",
  CITY_AGENT_MAPPING: "/city-agent-mapping",
  LOCALITY_AGENT_MAPPING: "/locality-agent-mapping",
  MANAGE_PACKAGES: "/manage-packages",
  MANAGE_REGIONS: "/manage-regions",
  MANUAL_ACTIVATION_DASHBOARD: "/manual-activation-dashboard",
  MANUAL_DIALING: "/manual-dialing",
  MERGE_ACCOUNTS: "/merge-accounts",
  MERGE_IP_DETECTED_ACCOUNTS: "/merge-ip-detected-accounts",
  MICROSITE: "/microsite",
  MIGRATION_CASES: "/migration-of-agents-cases",
  MONETIZABLE_CITIES: "/monetizable-cities",
  MY_ACCOUNTS: "/my-accounts",
  MY_OPPORTUNITIES: "/my-opportunities",
  NETSUITE_EVENT_TRACKING: "/netsuite-event-tracking",
  NETSUITE_PUSH_DATA: "/netsuite-push-data",
  OPPORTUNITY_SEARCH: "/opportunity-search",
  OWNER_DASHBOARD: "/owner-dashboard",
  PACKAGE_PRICING: "/package-pricing",
  PERFORMANCE: "/performance",
  PRODUCT_CONFIG: "/product-config",
  PRODUCT_MANAGEMENT: "/product-management",
  PRODUCT_PACKAGE_FAMILY: "/product-package-family",
  REPORTS: "/reports",
  RM_MIGRATION: "rm-migration",
  SALES_CLOSURE_DATE: "/sales-closure-date",
  SALES_REPORT: "/sales-report",
  SET_SALES_TARGET: "/set-sales-target",
  SITE_TAKEOVER: "/site-takeover",
  SLOT_AVAILABILITY: "/slot-availability",
  SLOT_CAPPING: "/slot-capping",
  STORIES: "/stories",
  TARGET_SETTING_RULES: "/target-setting-rules",
  TENANT_DASHBOARD: "/tenant-dashboard",
  USER_MANAGEMENT: "/user-management",
  VIRTUAL_TOUR: "/virtual-tour",
};
const navigationItems = [
  {
    label: "Home",
    route: ROUTES.HOME,
    icon: Home,
  },
  {
    label: "Owner Dashboard",
    route: ROUTES.OWNER_DASHBOARD,
    icon: AccountCircle,
    roles: [Constants.housingOwnerAgentRole],
  },
  {
    label: "Broker Dashboard",
    route: ROUTES.BROKER_DASHBOARD,
    icon: AccountCircle,
    roles: [Constants.brokerTelesalesAgentRole],
  },
  {
    label: "Builder Dashboard",
    route: ROUTES.BUILDER_DASHBOARD,
    icon: AccountCircle,
    roles: [Constants.builderTelesalesAgentRole],
  },
  {
    label: "Tenant Dashboard",
    route: ROUTES.TENANT_DASHBOARD,
    icon: AccountCircle,
    roles: [Constants.housingTMAgentRole],
  },
  {
    label: "My Opportunities",
    route: ROUTES.MY_OPPORTUNITIES,
    icon: Games,
  },
  {
    label: "Opportunity Search",
    route: ROUTES.OPPORTUNITY_SEARCH,
    icon: FindInPage,
  },
  {
    label: "Churn Scheduler",
    route: ROUTES.CHURN_SCHEDULER,
    icon: FindInPage,
    roles: [Constants.housingOwnerAgentRole],
  },
  {
    label: "Live Agent View",
    route: ROUTES.LIVE_VIEW,
    icon: TableChart,
  },
  {
    label: "My Accounts",
    route: ROUTES.MY_ACCOUNTS,
    icon: AccountBox,
  },
  {
    label: "Campaign Module",
    route: ROUTES.CAMPAIGN_MODULE,
    icon: ListAlt,
    roles: [Constants.adminRole],
  },
  {
    label: "Reports",
    route: ROUTES.REPORTS,
    icon: InsertDriveFile,
    roles: [Constants.adminRole],
  },
  {
    label: "Manual Dialing",
    route: ROUTES.MANUAL_DIALING,
    icon: Phone,
  },
  {
    label: "RM Migration",
    route: ROUTES.RM_MIGRATION,
    icon: SwapHorizontalCircle,
  },
  {
    label: "Migration of Owner Agents",
    route: ROUTES.MIGRATION_CASES,
    icon: PeopleIcon,
    roles: [Constants.adminRole],
  },
  {
    label: "Performance Dashboard",
    route: ROUTES.PERFORMANCE,
    icon: AssessmentIcon,
  },
  {
    label: "Slot Availability",
    route: ROUTES.SLOT_AVAILABILITY,
    icon: LocationCity,
  },
  {
    label: "AM Performance",
    route: ROUTES.AGENT_PERFORMANCE,
    icon: TrackChanges,
  },
  {
    label: Constants.SALES_INCENTIVE,
    icon: AttachMoney,
    roles: [Constants.lookupAdminRole],
    nestedMenus: [
      {
        label: "Target Setting Rules/Validations",
        route: ROUTES.TARGET_SETTING_RULES,
        icon: Payment,
        roles: [Constants.adminRole],
      },
      {
        label: "Set Sales Target",
        route: ROUTES.SET_SALES_TARGET,
        icon: GroupWork,
      },
      {
        label: "Incentive Calculation",
        route: ROUTES.INCENTIVE_CALCULATION,
        icon: TrendingUp,
      },
      {
        label: "Incentive Payout",
        route: ROUTES.INCENTIVE_PAYOUT,
        icon: Money,
        roles: [Constants.financeRole],
      },
    ],
  },
  {
    label: "Product Management",
    icon: LocalParking,
    roles: [Constants.lookupAdminRole, Constants.adminRole],
    nestedMenus: [
      {
        label: "Package Family",
        route: ROUTES.PRODUCT_PACKAGE_FAMILY,
        icon: Group,
      },
      {
        label: "Products",
        route: ROUTES.PRODUCT_MANAGEMENT,
        icon: ListIcon,
      },
      {
        label: "Package Pricing",
        route: ROUTES.PACKAGE_PRICING,
        icon: Group,
      },
      {
        label: "Packages",
        route: ROUTES.MANAGE_PACKAGES,
        icon: DonutLarge,
      },
      {
        label: "Events",
        route: ROUTES.EVENTS,
        icon: EventNote,
      },
      {
        label: "Product Config",
        route: ROUTES.PRODUCT_CONFIG,
        icon: Build,
      },
      {
        label: "Slot Capping",
        route: ROUTES.SLOT_CAPPING,
        icon: LocationCity,
      },
    ],
  },
  {
    label: "Download Reports",
    icon: Description,
    roles: [
      Constants.adminRole,
      Constants.modifyRoles.MANAGER_ROLE,
      Constants.masterData,
    ],
    nestedMenus: [
      {
        label: "Call Detail Report",
        route: ROUTES.CALL_DETAIL,
        icon: InsertDriveFile,
      },
      {
        label: "Sales Report",
        route: ROUTES.SALES_REPORT,
        icon: InsertDriveFile,
      },
      {
        label: "Invoice Report",
        route: ROUTES.INVOICE_REPORT,
        icon: InsertDriveFile,
      },
      {
        label: "Agent Performance Report",
        route: ROUTES.AGENT_PERFORMANCE_REPORT,
        icon: InsertDriveFile,
      },
    ],
  },
  {
    label: "Manual Activation Dashboard",
    icon: AssessmentIcon,
    route: ROUTES.MANUAL_ACTIVATION_DASHBOARD,
    roles: [Constants.masterData],
  },
  {
    label: "Ad Product",
    icon: PostAdd,
    nestedMenus: [
      {
        label: "Microsite",
        route: ROUTES.MICROSITE,
        icon: Web,
      },
      {
        label: "Virtual Tour",
        route: ROUTES.VIRTUAL_TOUR,
        icon: Web,
      },
      {
        label: "Product Config",
        route: ROUTES.AD_PRODUCT_CONFIG,
        icon: Build,
      },
      {
        label: "Site Takover",
        route: ROUTES.SITE_TAKEOVER,
        icon: Web,
      },
      {
        label: "Flats Panel",
        route: ROUTES.FLAT_PANEL,
        icon: Web,
      },
      {
        label: "Audience Maximizer",
        route: ROUTES.AUDIENCE_MAXIMIZER,
        icon: Web,
      },
      {
        label: "Stories",
        route: ROUTES.STORIES,
        icon: Web,
      },
      {
        label: "Lead Feedback",
        route: ROUTES.LEAD_FEEDBACK,
        icon: Web,
      },
    ],
  },
  {
    label: "Admin",
    icon: Fingerprint,
    roles: [
      Constants.adminRole,
      Constants.rcmApprovalAdmin,
      Constants.masterData,
      Constants.teleSalesUM,
    ],
    nestedMenus: [
      {
        label: "User Management",
        route: ROUTES.USER_MANAGEMENT,
        icon: AccountCircle,
        roles: [
          Constants.adminRole,
          Constants.lookupAdminRole,
          Constants.lookupUserManagement,
          Constants.teleSalesUM,
        ],
      },
      {
        label: "Digilite Videotour Mapping",
        route: ROUTES.DIGILITE_VIDEOTOUR_MAPPING,
        icon: MapRounded,
      },
      {
        label: "Event City Mapping",
        route: ROUTES.EVENT_CITY_MAPPING,
        icon: MapRounded,
      },
      {
        label: "City Agent Mapping",
        route: ROUTES.CITY_AGENT_MAPPING,
        icon: MapRounded,
      },
      {
        label: "Locality Agent Mapping",
        route: ROUTES.LOCALITY_AGENT_MAPPING,
        icon: PersonPinCircle,
        role: [Constants.adminRole],
      },
      {
        label: "Merge Accounts",
        route: ROUTES.MERGE_ACCOUNTS,
        icon: MergeType,
        role: [Constants.masterData, Constants.adminRole, Constants.lookupAdminRole],
      },
      {
        label: "Merge Accounts (IP)",
        route: ROUTES.MERGE_IP_DETECTED_ACCOUNTS,
        icon: MergeType,
      },
      {
        label: "Manage Regions",
        route: ROUTES.MANAGE_REGIONS,
        icon: PlayForWork,
        role: [Constants.adminRole],
      },
      {
        label: "Sales Closure Date",
        route: ROUTES.SALES_CLOSURE_DATE,
        icon: CalendarToday,
        role: [Constants.adminRole, Constants.lookupAdminRole],
      },
      {
        label: "Discount Approval Matrix",
        route: ROUTES.DISCOUNT_MATRIX,
        icon: Dashboard,
        role: [
          Constants.rcmApprovalAdmin,
          Constants.adminRole,
          Constants.lookupAdminRole,
        ],
      },
      {
        label: "Other Matrices",
        route: ROUTES.COMMON_MATRICES,
        icon: Dashboard,
        role: [Constants.adminRole, Constants.lookupAdminRole],
      },
      {
        label: "Netsuite Push Data",
        route: ROUTES.NETSUITE_PUSH_DATA,
        icon: AddToHomeScreen,
      },
      {
        label: "Netsuite Event Tracking",
        route: ROUTES.NETSUITE_EVENT_TRACKING,
        icon: Assessment,
      },
      {
        label: "Monetizable Cities",
        route: ROUTES.MONETIZABLE_CITIES,
        icon: AttachMoney,
      },
      {
        label: "Calling Agent Mapping",
        route: ROUTES.CALIING_AGENT_MAPPING,
        icon: AccountBox,
      },
    ],
  },
];

export { ROUTES, navigationItems as navigations };
