import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import { getAccountDetailsFromHousing } from "../../redux/actions/myAccounts.actions";
export const MARK_OPPORTUNITY_LOST = "MARK_OPPORTUNITY_LOST";
export const OPPORTUNITY_DETAILS = "OPPORTUNITY_DETAILS";
export const OPPORTUNITY_CREATED = "OPPORTUNITY_CREATED";
export const OPPORTUNITY_UPDATED = "OPPORTUNITY_UPDATED";
export const RESET_OPPORTUNITY_CREATION = "RESET_OPPORTUNITY_CREATION";
export const OPPORTUNITY_CREATION_FAILED = "OPPORTUNITY_CREATION_FAILED";
export const OPPORTUNITY_UPDATE_FAILED = "OPPORTUNITY_UPDATE_FAILED";
export const MILESTONE_CREATED = "MILESTONE_CREATED";
export const MILESTONE_UPDATED = "MILESTONE_UPDATED";
export const MILESTONE_DELETED = "MILESTONE_DELETED";
export const RESET_MILESTONE = "RESET_MILESTONE";
export const TICKETS_LIST = "TICKETS_LIST";
export const PROJECT_ATTRIBUTES = "PROJECT_ATTRIBUTES";
export const PROJECTS_LINKED_WITH_OPPORTUNITY = "PROJECTS_LINKED_WITH_OPPORTUNITY";
export const ALL_PROJECTS = "ALL_PROJECTS";
export const ALL_PROJECTS_IN_REGION = "ALL_PROJECTS_IN_REGION";
export const PROJECT_LINKING_FAILED = "PROJECT_LINKING_FAILED";
export const OPPORTUNITY_PACKAGE_OR_PROJECT_MAPPING_DELETED =
  "OPPORTUNITY_PACKAGE_OR_PROJECT_MAPPING_DELETED";
export const SENT_FOR_DISCOUNT_APPROVAL = "SENT_FOR_DISCOUNT_APPROVAL";
export const SENT_FOR_DISCOUNT_APPROVAL_FAILED = "SENT_FOR_DISCOUNT_APPROVAL_FAILED";
export const SENT_FOR_PRODUCT_APPROVAL = "SENT_FOR_PRODUCT_APPROVAL";
export const SENT_FOR_PRODUCT_APPROVAL_FAILED = "SENT_FOR_PRODUCT_APPROVAL_FAILED";
export const SENT_FOR_EXTENSION_APPROVAL = "SENT_FOR_EXTENSION_APPROVAL";
export const SENT_FOR_EXTENSION_APPROVAL_FAILED =
  "SENT_FOR_EXTENSION_APPROVAL_FAILED";
export const DISCOUNT_APPROVED = "DISCOUNT_APPROVED";
export const DISCOUNT_REJECTED = "DISCOUNT_REJECTED";
export const PRODUCT_APPROVED = "PRODUCT_APPROVED";
export const PRODUCT_REJECTED = "PRODUCT_REJECTED";
export const APPROVAL_HISTORY = "APPROVAL_HISTORY";
export const RESET_DISCOUNT_APPROVAL_HISTORY = "RESET_DISCOUNT_APPROVAL_HISTORY";
export const REVIEWRS_LIST = "REVIEWRS_LIST";
export const REVIEW_APPROVE_APPROVAL_FLOW = "REVIEW_APPROVE_APPROVAL_FLOW";
export const SENT_FOR_APPROVAL_FLOW = "SENT_FOR_APPROVAL_FLOW";
export const ALL_APPROVALS_NEEDED = "ALL_APPROVALS_NEEDED";
export const PAYMENTS_REVIEWRS_LIST = "PAYMENTS_REVIEWRS_LIST";
export const GENERATE_TAX_INVOICE = "GENERATE_TAX_INVOICE";
export const ALL_PROJECTS_OF_BUILDER = "ALL_PROJECTS_OF_BUILDER";
export const HOUSING_PROFILE_DATA = "HOUSING_PROFILE_DATA";
export const RESHARE_OWNER_OPP_LINK = "RESHARE_OWNER_OPP_LINK";
export const ASSIGN_OPPORTUNITY = "ASSIGN_OPPORTUNITY";
export const SENT_FOR_PRICE_RESTORATION = "SENT_FOR_PRICE_RESTORATION";
export const SET_OPPORTUNITY_TAGS_IN_ACCOUNT = "SET_OPPORTUNITY_TAGS_IN_ACCOUNT";
export const SET_OPPORTUNITY_TAG = "SET_OPPORTUNITY_TAG";
export const SET_USER_STATUS_OF_OPPORTUNITY = "SET_USER_STATUS_OF_OPPORTUNITY";
export const DONT_INITIATE_NEXT_CALL = "DONT_INITIATE_NEXT_CALL";
export const INITIATE_NEXT_CALL = "INITIATE_NEXT_CALL";
export const SET_MORTGAGE_DATA = "SET_MORTGAGE_DATA";
export const SET_MORTGAGE_PAYOUT_RANGE = "SET_MORTGAGE_PAYOUT_RANGE";
export const MORTGAGE_POSSIBLE_REMARKS = "MORTGAGE_POSSIBLE_REMARKS";
export const MORTGAGE_PARTNER_ACTION_SUCCESS = "MORTGAGE_PARTNER_ACTION_SUCCESS";
export const MORTGAGE_PARTNER_ACTION_FAILED = "MORTGAGE_PARTNER_ACTION_FAILED";
export const MORTGAGE_SUBMITTED_REMARKS = "MORTGAGE_SUBMITTED_REMARKS";
export const SET_MORTGAGE_DISBURSAL_API_FAIL = "SET_MORTGAGE_DISBURSAL_API_FAIL";
export const APPROVAL_IN_PROGRESS = "APPROVAL_IN_PROGRESS";
export const REVIEW_APPROVE_IN_PROGRESS = "REVIEW_APPROVE_IN_PROGRESS";
export const OWNER_PAID_DISCOUNT_DATA = "OWNER_PAID_DISCOUNT_DATA";

function parser(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return null;
  }
}

function parseOpportunityDetail(data) {
  if (data && data.opportunityPackageMappingDetails) {
    data.opportunityPackageMappingDetails.forEach((opportunity) => {
      opportunity.opportunityProductMappingDtos &&
        opportunity.opportunityProductMappingDtos.forEach((product) => {
          product.activationParameters = parser(product.activationParameters);
          product.requiredActivationParameters = parser(
            product.requiredActivationParameters
          );
          product.extraDetail = parser(product.extraDetail);
          product.fixedParameters = parser(product.fixedParameters);
        });
    });
  }
  return data;
}

export const getOpportunityDetail = (
  opportunityId,
  fetchPrevOpportunitiesDetails
) => {
  const url = `/sapna/v3/opportunity-page/${opportunityId}${
    fetchPrevOpportunitiesDetails ? "?fetchPrevOpportunitiesDetails=true" : ""
  }`;
  return (dispatch, getState) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        //  account details
        const parseOppDetail = parseOpportunityDetail(response.data.data);
        dispatch({
          type: OPPORTUNITY_DETAILS,
          payload: {
            opportunityId,
            data: parseOppDetail,
          },
        });
        const { account: { housingProfileId, clientTypeId } = {}, cityId } =
          parseOppDetail;
        const { userInfo = {} } = getState();
        const { isOwnerTelesalesAgent = false } = userInfo || {};

        if (isOwnerTelesalesAgent) {
          dispatch(getStatusOfOpportunityUser(housingProfileId));
        }
        if (
          Constants.MORTGAGE_SOURCES.includes(
            parseOppDetail.opportunitySource.toLowerCase()
          )
        ) {
          dispatch(getMortgageDisbursalRanges(clientTypeId, cityId));
          dispatch(getMortgageInformation(housingProfileId, opportunityId));
        }
        dispatch(
          getAccountDetailsFromHousing(parseOppDetail.account.housingProfileId)
        );
        dispatch(getOpportunityTags([opportunityId], SET_OPPORTUNITY_TAG));
        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};

// here
export const createOpportunity = (name, typeId, accountId, cityId) => {
  return (dispatch) => {
    const url = "/sapna/v1/opportunity";
    const data = {
      name,
      typeId,
      accountId,
      cityId,
    };
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: OPPORTUNITY_CREATED,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: OPPORTUNITY_CREATION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getBrokerLeadsCount = (housingProfileUUID) => {
  return (dispatch) => {
    const url = `/sapna/v1/opportunity-detail/broker-total-lead-count?housingProfileUuid=${housingProfileUUID}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};

export const updateOpportunity = (opportunityId, data) => {
  return (dispatch) => {
    const url = `/sapna/v1/opportunity/${opportunityId}`;
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: OPPORTUNITY_UPDATED,
          payload: {
            opportunityId,
            data: response.data.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: OPPORTUNITY_UPDATE_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetOpportunityCreation = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_OPPORTUNITY_CREATION,
    });
  };
};

export const deleteMilestone = (milestoneId) => {
  const url = `/sapna/v1/milestone/${milestoneId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "delete",
    })
      .then(() => {
        dispatch({
          type: MILESTONE_DELETED,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err) || "Unable to delete milestone",
        });
      });
  };
};

export const updateMilestone = (milestoneId, data) => {
  const url = `/sapna/v1/milestone/${milestoneId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
      data,
    })
      .then((response) => {
        dispatch({
          type: MILESTONE_UPDATED,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const markOpportunityLost = (oppToBeMarkedLost) => {
  const url = `/sapna/v1/markOpportunityAsLost`;
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data: oppToBeMarkedLost,
    })
      .then(() => {
        const arr = [oppToBeMarkedLost.sourceOpportunity];
        if (oppToBeMarkedLost.childOpportunity) {
          arr.push(oppToBeMarkedLost.childOpportunity);
        }
        dispatch({
          type: MARK_OPPORTUNITY_LOST,
          payload: arr,
        });
      })
      .catch(() => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg:
            "Unable to mark this opportunioty as lost, please try again after some time.",
        });
      });
  };
};

export const createMilestone = (data) => {
  const url = "/sapna/v1/milestone";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data: {
        ...data,
        paymentAdded: false,
      },
    })
      .then((response) => {
        dispatch({
          type: MILESTONE_CREATED,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg:
            Constants.getErrorMessage(err) || "Unable to create a new milestone",
        });
      });
  };
};

export const resetNewMilestone = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_MILESTONE,
    });
  };
};

export const getTicketsList = (entityId, entityTypeId) => {
  return (dispatch) => {
    const url = `/makaanTickets/api/v1/ticket/search?ticket_reference_entity_id=${entityId}&ticket_reference_entity_type_id=${entityTypeId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const ticketsList = response.data;
        dispatch({
          type: TICKETS_LIST,
          ticketsList,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const generateDigislateTaxInvoice = (termsheetId) => {
  const url = `/sapna/v1/invoice/generate-digislate-invoice?termsheetId=${termsheetId}`;
  return (dispatch) => {
    return (
      instance({
        url,
        method: "POST",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: GENERATE_TAX_INVOICE,
            payload: { status: true },
          });
          dispatch({
            type: Constants.API_FAIL,
            errMsg: "Tax Invoice generated successfully.",
          });
        })
        .catch((err) => {
          dispatch({
            type: GENERATE_TAX_INVOICE,
            payload: { status: false },
          });
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        })
    );
  };
};

export const getProjectTicketAttributes = () => {
  const url = "/makaanTickets/api/v1/ticket/master/category/4/attributes";
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const projectTicketAttributes = response.data.data;
        dispatch({
          type: PROJECT_ATTRIBUTES,
          projectTicketAttributes,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const linkOpportunityProject = (data, type, entityId) => {
  return (dispatch) => {
    let url = "";
    let method = "";
    if (type === "opportunityProject") {
      url = `/sapna/v2/opportunity/project-mapping/opportunity/${entityId}`;
      method = "post";
    } else if (type === "packageProject") {
      url = `/sapna/v1/opportunity/package/project-mapping/${entityId}`;
      method = "put";
    }
    return instance({
      url,
      method,
      data,
    })
      .then((response) => {
        dispatch({
          type: PROJECTS_LINKED_WITH_OPPORTUNITY,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: PROJECT_LINKING_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllProjects = (name) => {
  const url = `/sapna/v1/housing/helper/4?string=${name}&type=project`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: ALL_PROJECTS,
          allProjects: response.data.data.data.results,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getAllProjectsInRegion = (inputValue, category) => {
  let queryParams = {
    type: "project",
    string: inputValue,
  };
  if (!isNaN(inputValue)) {
    queryParams["entity_ids[]"] = inputValue;
  }
  if (category) queryParams.property_category = category;
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${process.env.DOMAINS.SEARCH}api/v1/search/suggest?${queryString}`;
  return (dispatch) => {
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        const { data: { data: { results = [] } = {}, error } = {} } = response;
        if (error) {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: `Error: ${JSON.stringify(error)}`,
          });
        }
        dispatch({
          type: ALL_PROJECTS_IN_REGION,
          value: results,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getProjectsOfBuilder = (profileUUID) => {
  const url = `/projects/active_search_by_builder?profile_uuid=${profileUUID}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        dispatch({
          type: ALL_PROJECTS_OF_BUILDER,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const deleteOpportunityPackageOrProjectMapping = (
  opportunityPackageOrProjectMappingId,
  entityType
) => {
  const url = `/sapna/v1/opportunity/${entityType}-mapping/${opportunityPackageOrProjectMappingId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "delete",
    })
      .then(() => {
        dispatch({
          type: OPPORTUNITY_PACKAGE_OR_PROJECT_MAPPING_DELETED,
          entityType,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendForDiscountApproval = (
  opprtunityId,
  refreshReviewersList,
  remark
) => {
  return (dispatch) => {
    const url = `/sapna/v2/opportunity/${opprtunityId}/send-for-discount-approval`;
    return instance({
      url,
      method: "post",
      data: {
        remark: remark || "",
      },
    })
      .then((response) => {
        refreshReviewersList();
        dispatch({
          type: SENT_FOR_DISCOUNT_APPROVAL,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_FOR_DISCOUNT_APPROVAL_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendForProductApproval = (
  opprtunityId,
  refreshReviewersList,
  remark,
  refreshOpp
) => {
  return (dispatch) => {
    const url = `/sapna/v1/approval-reviewal/send-for-approval`;
    return instance({
      url,
      method: "post",
      data: {
        approvalType: "ProductApproval",
        entityId: opprtunityId,
        entityTypeId: 3,
        remark: remark || "",
      },
    })
      .then((response) => {
        refreshReviewersList();
        refreshOpp && refreshOpp();
        dispatch({
          type: SENT_FOR_PRODUCT_APPROVAL,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_FOR_PRODUCT_APPROVAL_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const extendPackageDays = (packageId, duration) => {
  return (dispatch) => {
    const url = `/sapna/v1/approval-reviewal/send-for-approval`;
    return instance({
      url,
      method: "post",
      data: {
        entityTypeId: 18,
        entityId: packageId,
        durationInDays: duration,
        approvalType: "ExtendSlotDate",
      },
    })
      .then((response) => {
        dispatch({
          type: SENT_FOR_EXTENSION_APPROVAL,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_FOR_EXTENSION_APPROVAL_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendPriceRestoration = (priceRestObjData) => {
  return (dispatch) => {
    const url = `/sapna/v1/approval-reviewal/send-for-approval`;
    return (
      instance({
        url,
        method: "post",
        data: priceRestObjData,
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          dispatch({
            type: SENT_FOR_PRICE_RESTORATION,
          });
        })
        .catch((err) => {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        })
    );
  };
};

export const discountApproveReject = (
  opportunityId,
  status,
  opportunityPackageMappingIds,
  isReviewer,
  refreshReviewersList,
  remark
) => {
  return (dispatch) => {
    const reviewStatus = isReviewer
      ? status === "Approved"
        ? "Reviewed"
        : "Rejected"
      : "";
    const method = isReviewer ? "put" : "post";
    const url = isReviewer
      ? `/sapna/v1/approval/process-review?opportunityId=${opportunityId}&status=${reviewStatus}&opportunityPackageMappingIds=${opportunityPackageMappingIds}`
      : `/sapna/v1/opportunity/${opportunityId}/process-discount?discount=${status}&opportunityPackageMappingIds=${opportunityPackageMappingIds}`;
    return instance({
      url,
      method,
      data: {
        remark: remark || "",
      },
    })
      .then((response) => {
        if (
          !["Rejected", "Approved"].includes(response.data.data.discountApproved)
        ) {
          refreshReviewersList();
        }
        dispatch({
          type: `DISCOUNT_${status.toUpperCase()}`,
          isReviewer,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const productApproveReject = (
  opportunityId,
  status,
  opportunityPackageMappingIds,
  isReviewer,
  refreshReviewersList,
  remark
) => {
  return (dispatch) => {
    const method = "post";
    const url = `/sapna/v1/approval-reviewal/process-approval?status=${status}`;
    return instance({
      url,
      method,
      data: {
        approvalType: "ProductApproval",
        entityId: opportunityId,
        entityTypeId: 3,
        remark: remark || "",
      },
    })
      .then(() => {
        dispatch({
          type: `PRODUCT_${status.toUpperCase()}`,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendForApprovalFlow = (data) => {
  return (dispatch) => {
    const url = "/sapna/v1/approval-reviewal/send-for-approval";
    dispatch({
      type: APPROVAL_IN_PROGRESS,
    });
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch({
          type: SENT_FOR_APPROVAL_FLOW,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SENT_FOR_APPROVAL_FLOW,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getAllApprovals = (data = {}, type) => {
  return (dispatch) => {
    const url = `/sapna/v1/approval/all-approvals?${Constants.getRequestQueryParamsFromObj(
      data
    )}`;
    return instance({
      url,
      method: "GET",
    })
      .then((response) => {
        if (type) {
          handleApprovalResponse(dispatch, response.data.data[0], type);
        } else if (
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          const { data } = response.data;
          const result = {};
          data.forEach((approvalDto) => {
            let reviewerList =
              approvalDto.approvalReviewerMappings &&
              approvalDto.approvalReviewerMappings.map((mapping) => ({
                id: mapping.reviewerId,
                status: mapping.status,
              }));
            reviewerList &&
              (reviewerList.discountApprovingUserId =
                approvalDto.approvingAuthority.userId);
            reviewerList &&
              (reviewerList.approverName = approvalDto.approvingAuthority.name);
            result[approvalDto.entityId] = reviewerList;
          }, {});
          dispatch({
            type: PAYMENTS_REVIEWRS_LIST,
            paymentsReviewerList: result,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const reviewApproveApprovalFlow = (isReviewer, status, data) => {
  return (dispatch) => {
    const url = `/sapna/v1/approval-reviewal/process-${
      isReviewer ? "review" : "approval"
    }?status=${status}`;
    dispatch({
      type: REVIEW_APPROVE_IN_PROGRESS,
      payload: { status: status },
    });
    return instance({
      url,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch({
          type: REVIEW_APPROVE_APPROVAL_FLOW,
          payload: { status: true, data: response.data.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: REVIEW_APPROVE_APPROVAL_FLOW,
          payload: { status: false },
        });
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getApprovalHistory = (id, type, approvalType, entityTypeId = 3) => {
  let baseUrl = `/sapna/v1/approval`;
  let queryParams = {};

  queryParams["entityId"] = id;
  if (entityTypeId) queryParams["entityTypeId"] = entityTypeId;
  if (approvalType) queryParams["approvalType"] = approvalType;

  const queryString = new URLSearchParams(queryParams).toString();

  let url = `${baseUrl}?${queryString}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        handleApprovalResponse(dispatch, response.data.data, type);
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const resetApprovalHistory = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_DISCOUNT_APPROVAL_HISTORY,
    });
  };
};

const handleApprovalResponse = (dispatch, data, type) => {
  let approvalHistory = [];
  if (data) {
    approvalHistory.push({
      userName: `${data.approvingAuthority.name}`,
      designation: data.approvingAuthority.designationId,
      role: "Approver",
      status: Constants.camelCaseToTitle(data.status),
      updatedAt: data.updatedAt,
    });
  }
  if (data && data.approvalReviewerMappings) {
    data.approvalReviewerMappings.forEach((mapping) => {
      approvalHistory.push({
        userName: `${mapping.reviewer.name} `,
        designation: mapping.reviewer.designationId,
        role: "Reviewer",
        status: Constants.camelCaseToTitle(mapping.status),
        updatedAt: mapping.updatedAt,
      });
    });
  }
  if (type === "getReviewersList") {
    let reviewerList = data?.approvalReviewerMappings?.map((mapping) => ({
      id: mapping.reviewerId,
      status: mapping.status,
    }));
    reviewerList &&
      (reviewerList.discountApprovingUserId = data.approvingAuthority.userId);
    reviewerList.approvingAuthorityId = data?.approvingAuthorityId;
    dispatch({
      type: REVIEWRS_LIST,
      reviewerList,
    });
  } else {
    dispatch({
      type: APPROVAL_HISTORY,
      approvalHistory,
    });
  }
};

export const getHousingProfileDetails = (accountId) => {
  const url = `${process.env.DOMAINS.LOGIN}api/v1/profile-details-public?profile_uuids=${accountId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const profileData = response.data.results
          ? response.data.results[accountId]
          : {};
        dispatch({
          type: HOUSING_PROFILE_DATA,
          payload: profileData,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const reShareOwnerOppLink = (opportunityId) => {
  const url = `/sapna/v2/opportunity/reshare-payment-link?opportunityId=${opportunityId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then(() => {
        dispatch({
          type: RESHARE_OWNER_OPP_LINK,
          msg: "Details will be shared shortly",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const assignOpportunity = (opportunityId) => {
  const url = `/sapna/v3/opportunity/autoassign/${opportunityId}`;
  return (dispatch) => {
    return instance({
      url,
      method: "put",
    })
      .then((response) => {
        dispatch({
          type: ASSIGN_OPPORTUNITY,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getOpportunityTags = (opportunityIds = [], dispatchType) => {
  return (dispatch) => {
    if (opportunityIds.length) {
      const url = `/sapna/v1/report/opportunity?opportunityIds=${opportunityIds.join(
        ","
      )}`;
      return instance({
        url,
        method: "get",
      })
        .then((response) => {
          const data = response.data.data;
          dispatch({
            type: dispatchType,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: Constants.API_FAIL,
            errMsg: Constants.getErrorMessage(err),
          });
        });
    }
  };
};

export const getStatusOfOpportunityUser = (housingProfileId = "") => {
  return (dispatch) => {
    const url = `/sapna//v1/housing/helper/21?profile_uuid=${housingProfileId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        dispatch({
          type: SET_USER_STATUS_OF_OPPORTUNITY,
          payload: { ...data, housingProfileId },
        });
      })
      .catch(() => {});
  };
};

export const getMortgageInformation = (housingProfileId = "", opportunityId) => {
  return (dispatch) => {
    const url = `/mortgage/partner/agreement-details?opportunity_id=${opportunityId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        if (data && data.partner_details) {
          const { onBoardingSubStatusId, onBoardingStatusId, id } =
            data.partner_details;
          if (
            onBoardingStatusId === 5 &&
            [14, 15, 16].includes(onBoardingSubStatusId)
          ) {
            dispatch(getPossibleRemarks(2));
            dispatch(getSubmittedRemarks(id, 2));
          } else {
            dispatch(getPossibleRemarks(1));
            dispatch(getSubmittedRemarks(id, 1));
          }
        }
        dispatch({
          type: SET_MORTGAGE_DATA,
          payload: { ...data, housingProfileId, opportunityId },
        });
      })
      .catch((err) => {
        dispatch({
          type: MORTGAGE_PARTNER_ACTION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getMortgageDisbursalRanges = (clientTypeId, cityId) => {
  return (dispatch) => {
    const url = `/mortgage/possible-disbursal-ranges?client_type_id=${clientTypeId}&city_id=${cityId}`;
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const data = response.data.data;
        const {
          possible_percentage_share_range: possiblePayoutRange,
          possible_disbursal_ranges: disbursalRanges,
          number_of_dropdowns_for_range_selection: totalRangesToSelect,
          possible_agreement_validity_in_months: possibleAgreementValidity,
        } = data;

        dispatch({
          type: SET_MORTGAGE_PAYOUT_RANGE,
          payload: {
            possiblePayoutRange,
            disbursalRanges: Constants.transformDisbursalRanges(disbursalRanges),
            totalRangesToSelect,
            possibleAgreementValidity,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_MORTGAGE_DISBURSAL_API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPossibleRemarks = (remarkTypeId) => {
  return (dispatch) => {
    const url = `/mortgage/api/v0/possible-remarks`;
    return instance({
      url,
      method: "get",
      params: {
        remark_type_id: remarkTypeId,
      },
    })
      .then((response) => {
        dispatch({
          type: MORTGAGE_POSSIBLE_REMARKS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};

export const getSubmittedRemarks = (partnerId, remarkTypeId) => {
  return (dispatch) => {
    const url = "/mortgage/api/v0/remarks";
    return instance({
      url,
      method: "get",
      params: {
        remark_type_id: remarkTypeId,
        only_latest_remarks: true,
        mortgage_partner_id: partnerId,
        // change this to partnerId
      },
    })
      .then((response) => {
        dispatch({
          type: MORTGAGE_SUBMITTED_REMARKS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};

export const postMortgagePartnerStatusAction = (data) => {
  return (dispatch) => {
    const url = "/mortgage/api/v0/mortgage-partner-status-action";
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: MORTGAGE_PARTNER_ACTION_SUCCESS,
          payload: response.data.data,
        });
        location.reload();
      })
      .catch((err) => {
        dispatch({
          type: MORTGAGE_PARTNER_ACTION_FAILED,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const dontInitiateNextCall = () => (dispatch) =>
  dispatch({
    type: DONT_INITIATE_NEXT_CALL,
  });

export const getOwnerPaidDiscountData = () => (dispatch, getState) => {
  const { opportunity: { data: { account: { housingProfileId } = {} } = {} } = {} } =
    getState();
  return instance({
    url: `/sapna/v1/discount/fetch-renew-upsell-discounts?profileUuid=${housingProfileId}`,
    method: "get",
  })
    .then(({ data: { data = [] } }) => {
      const payload = data?.length
        ? data.reduce(
            (acc, { flatId, ...rest }) => ({
              ...acc,
              [flatId]: [...(acc[flatId] || []), rest],
            }),
            {}
          )
        : undefined;
      dispatch({
        type: OWNER_PAID_DISCOUNT_DATA,
        payload,
      });
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};
