import React from "react";
import { addMonths } from "date-fns";
import telesalesOpportunityConstants from "./TelesalesOpportunityConstants";

const clientStatuses = [
  {
    id: 1,
    label: "Hot",
  },
  {
    id: 2,
    label: "Cold",
  },
  {
    id: 3,
    label: "Warm",
  },
];
const clientStatusIdMap = clientStatuses.reduce(
  (prev, curr) => (prev[curr.id] = curr.label) && prev,
  {}
);
const getMapFromList = (list) =>
  list.reduce((result, key) => (result[key] = key) && result, {});
const constant = {
  dealDeskUserId: 9493307,
  emailIdsNotAllowed: ["dealdesk@housing.com", "lead.delivery@housing.com"],
  clientStatuses,
  searchOpportunityPages: [
    "prediscovery",
    "discovery",
    "buyinitiation",
    "orderconfirmation",
  ],
  lookupUserManagement: "LOOKUP_USER_MANAGEMENT",
  masterData: "LOOKUP_MASTER_DATA",
  adminRole: "Admin",
  lookupAdminRole: "Lookup Admin",
  financeRole: "Finance",
  apAdminRole: "Ap Admin",
  rcmApprovalAdmin: "RCM_APPROVAL_ADMIN",
  teleSalesUM: "TELESALES_UM",
  notEditableStatusId: {
    account: [5, 6],
    termsheet: [4, 5, 6],
    opportunity: [2, 6, 7],
    ownerOpportunity: [2, 6, 7, 8],
    tenantOpportunity: [2, 6, 7],
  },
  termsheetStatusId: {
    new: 1,
    pendingAtRCM: 2,
    approved: 3,
    rejectedByRCM: 4,
    cancelled: 5,
    dropped: 6,
    sentBackToRAM: 7,
  },
  types: [
    {
      name: "Up Sell",
      id: 1,
    },
    {
      name: "Subscription",
      id: 2,
    },
  ],
  agentStatus: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  cilentTypeIdNameMapping: {
    1: "Builder",
    2: "Broker",
    3: "Owner",
    4: "Financial Institutuon",
    5: "Marketing Agency",
  },
  packageHSNcodes: [
    {
      label: "998365: Listing",
      value: 998365,
    },
    {
      label: "847170: Hardware",
      value: 847170,
    },
    {
      label: "998439: Online/Software",
      value: 998439,
    },
    {
      label: "998716: AMC of Hardware Products and Repairs",
      value: 998716,
    },
    {
      label: "998399: Alliances/Partnerships/Contracts",
      value: 998399,
    },
    {
      label: "997159: Other services auxiliary to financial services",
      value: 997159,
    },
  ],
  nonMandatoryDesignationIds: [55, 52, 53, 31],
  linkSentStatusIds: [1, 57],
  HousingSalesManagerRoleIds: [
    "Housing Sales Manager",
    "Broker Tele Sales Team Lead",
    "Broker Tele Sales Process Manager",
    "Broker Tele Sales Business Manager",
    "Builder Tele Sales Team Lead",
    "Builder Tele Sales Process Manager",
    "Builder Tele Sales Business Manager",
  ],
  MakaanSalesManagerRoleIds: ["MakaanSalesManager"],
  myOpportunitiesKeys: [
    "closed",
    "followup",
    "housing-product-opportunities",
    "sales-connect-opportunities",
  ],
  myOpportunitiesBrokerTelesalesKeys: [
    "closed",
    "splfollowup",
    "housing-product-opportunities",
    "sales-connect-opportunities",
  ],
  salesconnectKeys: [
    "total-sc-opp",
    "activity-done",
    "conversion",
    "assigned-to-am",
    "pending-to-be-assigned-to-am",
  ],
  selectiveBranchCities: ["Mumbai1", "Mumbai2", "Navi Mumbai", "Thane"],
  contentProductId: [59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72],
  contentPackageId: [
    7, 22, 30, 31, 161, 162, 47, 57, 68, 69, 70, 71, 72, 74, 359, 362, 394, 363,
  ],
  adPackageId: [
    2, 3, 4, 5, 62, 63, 103, 104, 78, 79, 81, 65, 94, 225, 226, 227, 228, 230, 232,
    233, 234, 269, 281, 283, 284, 387, 286, 385, 518,
  ],
  developerPackages: [
    2, 3, 4, 5, 62, 63, 103, 104, 31, 161, 162, 225, 226, 227, 228, 230, 232, 233,
    234, 269, 281, 283, 284, 387, 286, 363, 385, 518, 635, 1041, 1759, 1749, 1751,
    1769, 1854, 1855, 1856, 1857, 1987, 1988, 1989, 1990, 2518, 2519, 2498, 2499,
    2502, 2503, 2506, 2298, 2299, 2300, 2301, 2305, 2506, 2637, 2302, 2303, 2304,
    2306, 2307, 2507, 2638,
  ],
  backlistedProductIds: [12, 51, 52, 53, 54, 58, 84, 81, 82, 83, 87, 177], // value plus products and 87 for housing expert and 177 for housing expert pro
  eventProductIds: [5, 37, 156, 178],
  dynamicPropertiesKeys: {
    restrictedPinningProjects: "restricted.pinning.project.ids",
    digiliteVideotour: "digilite.videotour.listing.caps.key",
    eventCity: "city.uuid.event.id.mapping",
  },
  digislatePackageIdsDynamicProperty: "digislate.package.ids",
  paTabProductIdsDynamicProperty: "developer.auto.activation.product.ids",
  regionsIdsFor180Days: [1, 2, 4 /** housingSubscriptionRegionIdForChennai */],
  packageIdsFor180DaysValidation: [110],
  pacageIdForChennaiCityOnly: 83,
  packageIdWithChennaiPricesFor180DaysOnly: 213,
  packageIdWithHyderabadPricesFor90DaysOnly: 110,
  chennaiCityUUID: "ad008522917b9e167c3b",
  housingSubscriptionRegionIdForChennai: 11,
  housingSubscriptionRegionIdForHyderabad: 12,
  MarchOffer: {
    packageFamily: {
      id: 50,
      name: "March Offers",
    },
    packages: [
      163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178,
      179, 180, 181, 182, 183, 184, 185, 186, 187, 188,
    ],
  },
  pendingIncentiveStatus: [1, 2],
  signOffIncentiveStatus: 3,
  possibleManagerApprovedIncentiveStatuses: [3, 4],
  homeUtsavComplementry: {
    // LOOK-245
    packageId: 240, // Complementry Package Id
    packageIds: [235, 239], // Package Ids for which complementry to be given
    // 242: {"quantity":1,"casingType":"Standard Casing","surfaceSize":32,"productName":"Digislate Complimentary"},
    // 237: {"quantity":1,"casingType":"Standard Casing","surfaceSize":24,"productName":"Digislate Complimentary"}
    // Below case discussed over call with Manish K. and Gaurav J.
    235: {
      productName: "Digislate Complimentary",
      quantity: 1,
      casingType: "Standard Casing",
      surfaceSize: 24,
    },
    239: {
      productName: "Digislate Complimentary",
      quantity: 1,
      casingType: "Standard Casing",
      surfaceSize: 32,
    },
  },
  complementryDigislatePackageId: 188,
  ValuePlusPremierPackageId: 213,
  ValuePlusNPPinningPackageId: 214,
  NPPinningPackageId: 77,
  packageAgentTypeRadioOptions: [
    { key: "FoS", value: "FoS", label: "FoS" }, //first item of the array must always be the default value
    { key: "TeleSales", value: "Telesales", label: "TeleSales" },
    { key: "All", value: "All", label: "Both" },
  ],
  ownerPackageFamilyIds: [59, 60],
  npTypeRadioOptions: [
    { key: "main", value: "Main", label: "Main Product" }, //first item of the array must always be the default value
    { key: "complimentary", value: "Complimentary", label: "Complimentary Product" },
    { key: "na", value: "na", label: "Not Applicable" },
  ],
  propertyCategoryRadioOptions: [
    {
      key: "residential",
      value: "Residential",
      label: "Residential",
      isDisabled: false,
    }, //first item of the array must always be the default value
    {
      key: "commercial",
      value: "Commercial",
      label: "Commercial",
      isDisabled: true,
    },
    { key: "both", value: "Both", label: "Both", isDisabled: false },
  ],
  packageFamilyIdsWithCityLevelVisibility: [
    2 /** City Page Products */, 40 /** Site Takeover */, 41,
  ],
  packageFamilyIdsForSlotAvailability: [
    2 /** City Page Products */, 1 /** Locality Listing Page Products */,
    32 /** Locality Listing Page Products(Housing Makaan combo) */, 43 /** Broker */,
    40 /** Site Takeover */, 41,
  ],
  packageNPPinningId: 9,
  snackbarDuration: 4000,
  customPackageId: [8, 10],
  threePricePackageAgentType: ["Telesales", "All"],
  mediaTypeId: {
    housing: {
      digislateReceiptVoucherInvoice: 683,
      taxInvoiceMediaTypeId: 614,
      digislateInvoiceMediaTypeId: 622,
    },
    makaan: {
      taxInvoiceMediaTypeId: 634,
      digislateInvoiceMediaTypeId: 634,
    },
  },
  terminalStatusLabel: ["Dropped", "Lost", "Closed"],
  customPackageRoles: ["Custom Package", "LOOKUP_MASTER_DATA"],
  leadDeliveryRole: "Lead Delivery SPOC",
  modifyRoles: {
    MANAGER_ROLE: "Housing Sales Manager",
    AGENT_ROLE: "Housing Sales Agent",
    BROKER_TELE_SALES: "Broker Tele Sales",
    LOOKUP_VIEW: "LOOKUP VIEW",
  },
  readOnlyRoles: ["RCM_USER", "Ticketing"],
  contentUserRole: "Housing Content",
  financeUserRole: "Finance",
  housingOwnerAgentRole: "Housing Owner Agent",
  brokerTelesalesAgentRole: "Broker Tele Sales",
  builderTelesalesAgentRole: "Builder Tele Sales",
  housingTMAgentRole: "TENANT_MONETIZATION_AGENT",
  audienceMaximizerAgentRole: "Audience Maximizer Agent",
  leadFeedbackAgent: "Lead Auditor",
  getAllowedRolesForLogin: () => {
    return Object.values(constant.modifyRoles);
  },
  companyId: {
    housing: 611174,
    makaan: 112548,
  },
  commonMatrixTypes: [
    "FreeTrial",
    "Refund",
    "LateCheckDeposition",
    "PriceRestoration",
    "DelayedActivation",
    "CreditApproval",
  ],
  deliveryStatuses: ["DELIVERY_STARTED", "DELIVERY_NOT_STARTED"],
  NPPinningExcludeOneInQuantityCitiesUUID: [
    "11e12081aa78a3375087",
    "526acdc6c33455e9e4e9",
    "e0d06082745ce684f01a",
  ], // LOOK-524
  regionalManagerDesignations: [
    "STM",
    "TM",
    "AH",
    "RBH",
    "RBM",
    "TL",
    "SAM",
    "AM",
    "Key Accounts",
    "Priority Partner",
  ],
  cityHeadDesignations: ["CH", "NSH", "CBO", "ZM"],
  fixedTdsAccountIds: [
    37, 71, 91, 124, 142, 173, 206, 224, 274, 326, 349, 353, 368, 370, 373, 403, 444,
    453, 467, 488, 503, 517, 521, 542, 544, 564, 565, 567, 588, 589, 593, 623, 628,
    630, 663, 666, 675, 682, 716, 740, 765, 770, 783, 784, 797, 811, 816, 824, 832,
    854, 869, 904, 905, 910, 925, 940, 941, 947, 953, 960, 987, 989, 1003, 1037,
    1042, 1044, 1055, 1070, 1082, 1094, 1095, 1105, 1108, 1139, 1162, 1240, 1292,
    1296, 1301, 1307, 1316, 1317, 1320, 1494, 1508, 1586, 1589, 1618, 1626, 1638,
    1661, 1674, 1683, 1685, 1721, 1753, 1763, 1776, 1777, 1796, 1798, 1841, 1845,
    1895, 1896, 1898, 1951, 1970, 2041, 2129, 2146, 2148, 2213, 2233, 2263, 2272,
    2317, 2332, 2335, 2475, 2508, 2525, 2539, 2556, 2674, 2715, 2752, 2782, 2796,
    2797, 2871, 2884, 2920, 2940, 2945, 2970, 2998, 3029, 3039, 3087, 3111, 3135,
    3149, 3179, 3189, 3259, 3295, 3296, 3298, 3329, 3333, 3372, 3373, 3379, 3381,
    3382, 3385, 3395, 3424, 3430, 3465, 3466, 3486, 3490, 3495, 3515, 3592, 3642,
    3661, 3737, 3750, 3797, 3848, 3902, 3979, 4025, 4069, 4081, 4114, 4139, 4185,
    4207, 4215, 4222, 4255, 4273, 4276, 4278, 4310, 4348, 4355, 4440, 4491, 4525,
    4543, 4544, 4558, 4584, 4591, 4596, 4614, 4619, 4657, 4667, 4669, 4676, 4678,
    4697, 4707, 4709, 4712, 4718, 4720, 4721, 4732, 4734, 4770, 4846, 4883, 4892,
    4895, 4949, 4972, 4980, 4997, 5032, 5045, 5087, 5103, 5276, 5283, 5299, 5421,
    5512, 5531, 5577, 5588, 5628, 5642, 5658, 5800, 5864, 5965, 5967, 5974, 6019,
    6121, 6130, 6207, 6217, 6291, 6311, 6346, 6411, 6564, 6581, 6600, 6641, 6651,
    6656, 6662, 6691, 6709, 6714, 6720, 6750, 6861, 6880, 7024, 7111, 7189, 7307,
    7334, 7384, 7452, 53959, 54349, 55319, 60008, 60327, 63035, 86251, 99738, 100332,
    100552, 100641, 100842, 101108, 101260, 101536, 101594, 101684, 101867, 102843,
    102848, 132997, 133125, 133126, 133272, 133518, 133858, 133937, 133971, 134260,
    135133, 135684, 138736, 139999, 141382, 141936, 143030, 143590, 144143, 144981,
    145060, 145574, 146007, 146148, 146221, 147322, 147564, 147585, 147586, 147932,
    147938, 148179, 149212, 149579, 149971, 150330, 150923, 151667, 152390, 153383,
    157335, 158438, 159096, 160005, 160303, 160317, 161499, 161571, 162188, 163011,
    164629, 165837, 165949, 166998, 167420, 272354, 272361, 272498, 272519, 272558,
    273098, 279199, 280998, 320251, 328460,
  ],
  allLocalityForPackageId: [8, 4, 10, 17],
  termsheetStatusToNotAllowAddition: [1, 2, 3],
  termsheetStatusToAllowPDFDownload: [1, 2],
  pageSize: 5,
  tableSize: 10,
  minimumCount: 10,
  opportunityEntityId: 1,
  accountEntityId: 3,
  termsheetEntityId: 2,
  termsheetEntityTypeId: 4,
  entityIdsMap: {
    account: 1,
    legalEntity: 2,
    contact: 21,
  },
  ticketingCategories: {
    newAccount: 1,
    mapAccount: 38,
    reMapAccount: 2,
    pocCreation: 3,
    projectCreation: 4,
    packageActivation: 5,
    packageActivationOwner: 104,
    legalEntity: 6,
  },
  ticketingSubcategories: {
    newAccount: {
      Builder: 1,
      Broker: 186,
    },
    mapAccount: 92,
    ownerPackageActivation: 384,
  },
  accountMapTicketAttributes: {
    name: 113,
    housingProfileId: 125,
    pocEmail: 116,
    city: 114,
  },
  accountMapTicketAttributesBETA: {
    name: 113,
    housingProfileId: 125,
    pocEmail: 116,
    city: 114,
  },
  accountReMapAttributes: {
    name: 8,
    pocEmail: 46,
    salesAccountId: 126,
  },
  newAccountTicketAttributes: {
    name: 1,
    city: 2,
    pocName: 3,
    pocEmail: 4,
    pocMobile: 5,
    entityName: 6,
    billingAddress: 7,
  },
  pocCreationTicketFields: {
    name: 10,
    email: 11,
    phone: 12,
    pocType: 13,
    housingProfileId: 44,
    projectId: 155,
    department: 158,
  },
  packageActivationTicketFields: {
    city: 17,
    region: 18,
    packageName: 19,
    duration: 20,
    termsheetId: 62,
    listingId: 261,
  },
  ownerPackageActivationTicketFields: {
    termsheetId: 271,
  },
  projectCreationAttributes: {
    projectName: 14,
    builderName: 15,
    localityName: 16,
    city: 43,
    opportunityId: 66,
  },
  legalEntityAttributes: {
    accountUUID: 21,
    legalName: 36,
    pan: 37,
    tan: 377,
    gst: 38,
    deliveryState: 39,
    billingState: 40,
    billingAddress: 41,
    billingAddressPin: 42,
    billingCity: 221,
    shippingCity: 222,
    isUnregisteredCustomer: 404,
    correspondenceAddress: 42,
    shippingAddress: 99,
    shippingAddressPin: 100,
  },
  pdfTypes: {
    housing: {
      milestone: {
        performa: 612,
        degislate: 613,
      },
    },
    makaan: {
      milestone: {
        performa: 636,
        degislate: 636,
      },
    },
  },
  legalEntityAttributesFields: [
    {
      id: "36",
      name: "Legal Name",
      excludeSpecialChars: true,
      is_mandatory: "1",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "STRING",
      },
      priority: "2",
    },
    {
      id: "37",
      name: "PAN",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "STRING",
      },
      priority: "3",
    },
    {
      id: "377",
      name: "TAN",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "STRING",
      },
      priority: "4",
    },
    {
      id: "38",
      name: "GST",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "STRING",
      },
      priority: "5",
    },
    {
      id: "404",
      name: "Is Unregistered Customer",
      user_input_required: "1",
      data_type: {
        id: "5",
        name: "CHECKBOX",
      },
      infoMessage: "No GST credit will be received by Unregistered Customer",
      priority: "5",
    },
    {
      id: "41",
      name: "Billing Address",
      excludeSpecialChars: true,
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "10",
        name: "TEXTAREA",
      },
      priority: "7",
    },
    {
      id: "221",
      name: "Billing Address City",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "PT_CITY",
      },
      priority: "8",
    },
    {
      id: "42",
      name: "Billing Address PIN Code",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "PINCODE",
        type: "number",
      },
      priority: "9",
    },
    {
      id: "99",
      name: "Shipping Address",
      excludeSpecialChars: true,
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "10",
        name: "TEXTAREA",
      },
      priority: "10",
    },
    {
      id: "222",
      name: "Shipping Address City",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "PT_CITY",
      },
      priority: "11",
    },
    {
      id: "100",
      name: "Shipping Address PIN Code",
      is_mandatory: "0",
      user_input_required: "1",
      data_type: {
        id: "1",
        name: "PINCODE",
        type: "number",
      },
      priority: "12",
    },
  ],
  projectCreationSubCategory: 4,
  daysWindowForPaymentDueDate: 30,
  minDateAllowed: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
  maxDateAllowed: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
  commentActivityType: ["Email", "Call", "Dropin", "Meeting"],
  ownerCommentActivityType: ["Call"],
  connectedId: 12,
  notConnectedId: 13,
  ownerConnected: "Connected",
  ownerNotConnected: "Not Connected",
  commentsForConnected: [6, 9, 23, 257, 258, 259, 260, 279],
  commentsForConnectedOwner: [386, 388, 389, 390],
  commentsForNotConnected: [11, 73, 75, 255],
  commentsForConnectedBrokerTS: [
    278, 261, 220, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 360,
    361, 356, 23,
  ],
  serviceCallDoneReasonId: 374,
  serviceRequestRaisedReasonId: 375,
  commentsForServiceCallConnected: [374, 375],
  commentsForNotConnectedBrokerTS: [11, 73, 75, 255, 275],
  commentsWithFollowUp: [360, 355, 356],
  commentsForDropin: [147, 166],
  opportunityDiscountStatusLabel: {
    Required: "Required",
    NotRequired: "Not Required",
    PendingApproval: "Pending Approval",
    Approved: "Approved",
    Rejected: "Rejected",
  },
  productApprovalStatusLabel: {
    Required: "Required",
    NotRequired: "NotRequired",
    PendingApproval: "PendingApproval",
    Approved: "Approved",
    Rejected: "Rejected",
  },
  delayedActivationStatusLabel: {
    NotApplicable: "Not Applicable",
    Required: "Required",
    NotRequired: "Not Required",
    PendingApproval: "Pending Approval",
    Approved: "Approved",
    Rejected: "Rejected",
    Lapsed: "Lapsed",
  },
  keyMapV0_V2: {
    types: {
      integer: "Integer",
      region: "Region",
      locality: "Locality",
      string: "String",
      housingSubscriptionRegion: "HousingSubscriptionRegion",
      autopopulate: "autopopulate",
      city: "City",
      date: "Date",
    },
    fields: {
      city: "cityUUID",
      region: "regionId",
      quantity: "quantity",
      promotedDays: "promotedDays",
      validity: "validity",
      housingSubscriptionRegionId: "housingSubscriptionRegionId",
      startDate: "startDate",
    },
  },
  reports: {
    closureTrend: {
      label: "Closure Trend",
      key: "ClosureTrend/MonthlyClosures",
      allowedRoles: ["RCM_USER", "Ticketing"],
    },
    collections: {
      label: "Collections",
      key: "Collections/Citywise-Collection",
      allowedRoles: ["RCM_USER", "Ticketing"],
    },
    opportunityFunnel: {
      label: "Opportunity Funnel",
      key: "OpportunityFunnel/Table",
      allowedRoles: ["RCM_USER", "Ticketing"],
    },
    collectionTracker: {
      label: "Collection Tracker",
      key: "Collection-Tracker/Table",
      allowedRoles: ["RCM_USER", "Ticketing"],
    },
  },

  salesReportsColumns: {
    agentName: { label: "Name (Design.)", key: "agentName", type: "link" },
    agentId: { label: "Id", key: "agentId" },
    assignedOpportunities: { label: "Assign. Opp", key: "assignedOpportunities" },
    numberOfUniqueAccounts: {
      label: "No. of Unique acct.",
      key: "numberOfUniqueAccounts",
    },
    totalCount: { label: "Opp with Activity Done", key: "totalCount" },
    coldAccountsData: {
      label: "Cold",
      key: "numberOfUniqueAccounts",
      type: "subHead",
      subValue: [
        { label: "No. of Accounts", key: "numberOfAccounts" },
        { label: "Deal Value", key: "dealValue" },
      ],
      subCat: {
        numberOfAccounts: {
          label: "No. of Accounts",
          key: "numberOfAccounts",
        },
        dealValue: {
          label: "Deal Value",
          key: "dealValue",
        },
      },
    },
    hotAccountsData: {
      label: "Hot",
      key: "hotAccountsData",
      type: "subHead",
      subValue: [
        { label: "No. of Accounts", key: "numberOfAccounts" },
        { label: "Deal Value", key: "dealValue" },
      ],
      subCat: {
        numberOfAccounts: {
          label: "No. of Accounts",
          key: "numberOfAccounts",
        },
        dealValue: {
          label: "Deal Value",
          key: "dealValue",
        },
      },
    },
    warmAccountsData: {
      label: "Warm",
      key: "warmAccountsData",
      type: "subHead",
      subValue: [
        { label: "No. of Accounts", key: "numberOfAccounts" },
        { label: "Deal Value", key: "dealValue" },
      ],
      subCat: {
        numberOfAccounts: {
          label: "No. of Accounts",
          key: "numberOfAccounts",
        },
        dealValue: {
          label: "Deal Value",
          key: "dealValue",
        },
      },
    },
    closedAccountsData: {
      label: "Closed",
      key: "closedAccountsData",
      type: "subHead",
      subValue: [
        { label: "No. of Accounts", key: "numberOfAccounts" },
        { label: "Deal Value", key: "dealValue" },
      ],
      subCat: {
        numberOfAccounts: {
          label: "No. of Accounts",
          key: "numberOfAccounts",
        },
        dealValue: {
          label: "Deal Value",
          key: "dealValue",
        },
      },
    },
  },
  stateMovementColumn: {
    firstColumn: { label: "", key: "firstColmn" },
    cold: { label: "Cold", key: "cold" },
    warm: { label: "Warm", key: "warm" },
    hot: { label: "Hot", key: "hot" },
    order: { label: "Order", key: "order" },
  },
  stateMovementColumn2: ["Cold", "Warm", "Hot", "Order"],
  SNACKBAR_NOTIFICATION: "SNACKBAR_NOTIFICATION",
  tableauUsername: "tech_admin",
  TABLEAU_BASE_URL: "https://analytics.proptiger.com/",
  API_FAIL: "API_FAIL",
  API_SUCCESS: "API_SUCCESS",
  CLOSE_COMMON_DIALOG: "CLOSE_COMMON_DIALOG",
  CLEAR_TICKETS_LIST: "CLEAR_TICKETS_LIST",
  RESET_OPPORTUNITY: "RESET_OPPORTUNITY",
  RESET_ACCOUNT: "RESET_ACCOUNT",
  PACKAGE_QUANTITY_MISMATCH: "PACKAGE_QUANTITY_MISMATCH",
  validImageTypes: ["image/gif", "image/jpeg", "image/png", "application/pdf"],
  documentTypes: {
    LookupPackage: ["Package Delivery Done", "Package Assembly Done"],
    Payment: ["Cheque", "Deposit Slip", "Demand Draft", "Credit Approval"],
    Termsheet: ["Signed Termsheet"],
    MortgagePartner: ["Mortgage Documents"],
  },
  multiProjectPackageIds: [
    18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 87, 88, 89, 90, 91, 92, 93, 94, 95, 97,
  ],
  domains: {
    makaan: {
      id: 1,
      label: "Makaan",
    },
    housing: {
      id: 5,
      label: "Housing",
    },
    housingMarketPlace: {
      id: 7,
      label: "Housing Market Place",
    },
  },
  termsheet: {
    cancelStatus: 5,
    sentToRAM: 7,
    acceptedAcceptanceStatus: 5,
    rejectedAcceptanceStatus: 6,
    expiredAcceptanceStatus: 7,
  },
  dashboardEntities: {
    followup: {
      useDateFilter: true,
      name: "Followup",
      addAsFilter: true,
      statusId: [1, 44, 46, 57],
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      dateFilter: "fupTime",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
    },
    splfollowup: {
      useDateFilter: true,
      name: "Followup",
      addAsFilter: true,
      statusId: [1, 44, 46, 57],
      reasonId: [260, 355, 356],
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      dateFilter: "fupTime",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
    },
    renewal: {
      name: "Renewal",
      addAsFilter: true,
      statusId: [1, 22, 44, 46, 57],
      opportunityTypeId: 3,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
    },
    closed: {
      name: "Closed",
      useDateFilter: true,
      dateFilter: "timeRange",
      addAsFilter: true,
      key: "CLOSED_OPPORTUNITIES",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
    },
    "housing-product-opportunities": {
      name: "Housing Product",
      useDateFilter: true,
      addAsFilter: true,
      opportunitySource: "HousingProduct",
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      dateFilter: "createdAt",
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "sales-connect-opportunities": {
      name: "Sales Connect",
      useDateFilter: true,
      addAsFilter: true,
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      dateFilter: "createdAt",
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "pending-termsheet": {
      name: "Pending Termsheet Approval",
      addAsFilter: true,
      statusId: 33,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
    },
    "payment-approved": {
      useDateFilter: true,
      dateFilter: "timeRange",
      name: "Payment Approved",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_APPROVED_OPPORTUNITIES",
    },
    "payment-rejected": {
      useDateFilter: true,
      dateFilter: "timeRange",
      name: "Payment Rejected",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_REJECTED_OPPORTUNITIES",
    },
    "payment-pending": {
      name: "Payment Pending",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_PENDING_OPPORTUNITIES",
    },
    "pending-discount-approval": {
      name: "Pending Discount Approvals - Requested by team",
      addAsFilter: true,
      discountApproved: "PendingApproval",
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
    },
    "disount-approval-pending-on-me": {
      name: "Discount Approvals - Pending on me",
      useDateFilter: true,
      dateFilter: "assignedAt",
      addAsFilter: true,
      discountApproved: "PendingApproval",
      approvalAuthority: true,
      key: "BASED_ON_OUTER_FILTER",
      sortKey: [
        {
          field: "assignedAt",
          sortOrder: "DESC",
        },
      ],
    },
    "discount-reviews-pending-on-me": {
      name: "Discount Reviews - Pending on me",
      useDateFilter: true,
      dateFilter: "assignedAt",
      addAsFilter: true,
      discountApproved: "PendingApproval",
      approvalReviewers: true,
      key: "BASED_ON_OUTER_FILTER",
      sortKey: [
        {
          field: "assignedAt",
          sortOrder: "DESC",
        },
      ],
    },
  },
  mortgageEntities: {
    "mortgage-pending-rcm-approval": {
      label: "Mortgage Registration - Pending RCM Approval",
      name: "PendingAtRCM",
      approvalType: "KycApprovalByRCM",
      countKey: "PendingAtRCM",
    },
    "mortgage-rcm-approved": {
      label: "Mortgage Registration - Approved By RCM",
      name: "ApprovedByRCM",
      approvalType: "KycApprovalByRCM",
      countKey: "ApprovedByRCM",
    },
    "mortgage-rejected-by-rcm": {
      label: "Mortgage Registration - Rejected by RCM",
      name: "RejectedByRCM",
      approvalType: "KycApprovalByRCM",
      countKey: "RejectedByRCM",
    },
    "mortgage-pending-rcm-approval-bu": {
      label: "Mortgage Bank Account Updation - Pending at RCM",
      name: "PendingAtRCM",
      approvalType: "BankDetailsApprovalByRCM",
      countKey: "PendingAtRCM_BU",
    },
    "mortgage-rcm-approved-bu": {
      label: "Mortgage Bank Account Updation - Approved By RCM",
      name: "ApprovedByRCM",
      approvalType: "BankDetailsApprovalByRCM",
      countKey: "ApprovedByRCM_BU",
    },
    "mortgage-rejected-by-rcm-bu": {
      label: "Mortgage Bank Account Updation - Rejected by RCM",
      name: "RejectedByRCM",
      approvalType: "BankDetailsApprovalByRCM",
      countKey: "RejectedByRCM_BU",
    },
    "mortgage-registration-form-filling-in-progress-cityhead": {
      label: "Mortgage Registration -  Form Filling in-Progress",
      name: "FormFillingInProgress",
      countKey: "FormFillingInProgress",
    },
    "mortgage-interest-shown-cityhead": {
      label: "Mortgage Interest-Shown - Opportunity Created",
      name: "InterestShown",
      countKey: "InterestShown",
    },
  },
  mortgageCityHeads: [
    "shailesh.deshpande@housing.com",
    "shashank.jadhav@housing.com",
    "jitendra.bhati@housing.com",
    "ankur.yadav@housing.com",
    "nageshwar.rao@housing.com",
    "imran.mohammedtousif@housing.com",
    "devang.more@housing.com",
  ],
  salesReportActivityDone: {
    useDateFilter: true,
    addBothRangeAndDateAsFilter: true,
    addAsFilter: true,
    dateFilter: "createdAt",
    name: "Opp with Activity Done",
    key: "SALES_OPPORTUNITY_ALL_ACTIONS",
    opportunityCampaigns: ["direct_partners_page", "call_center_number"],
    sortKey: [
      {
        field: "createdAt",
        sortOrder: "DESC",
      },
    ],
  },
  salesCollectEntities: {
    collection: {
      useDateFilter: true,
      dateFilter: "createdAt",
      name: "collection (INR)",
      addAsFilter: true,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
  },
  salesConnectEntities: {
    "total-sc-opp": {
      useDateFilter: true,
      dateFilter: "createdAt",
      name: "Total SC Opp",
      addAsFilter: true,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "activity-done": {
      name: "Activity Done",
      useDateFilter: true,
      addAsFilter: true,
      addBothRangeAndDateAsFilter: true,
      dateFilter: "createdAt",
      key: "SALES_OPPORTUNITY_ALL_ACTIONS",
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    conversion: {
      name: "Conversion",
      useDateFilter: true,
      dateFilter: "createdAt",
      addAsFilter: true,
      statusId: [22],
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "assigned-to-am": {
      name: "Assigned to AM",
      useDateFilter: true,
      dateFilter: "createdAt",
      addAsFilter: true,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      agentDesignationId: [29, 30],
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "pending-to-be-assigned-to-am": {
      name: "Pending to be assigned to AM",
      useDateFilter: true,
      dateFilter: "createdAt",
      addAsFilter: true,
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      agentDesignationId: [35, 36, 37, 38, 39],
      opportunityCampaigns: ["direct_partners_page", "call_center_number"],
      sortKey: [
        {
          field: "createdAt",
          sortOrder: "DESC",
        },
      ],
    },
    "payment-failed-report": {
      key: "BASED_ON_OUTER_FILTER_WITH_AGENT_FILTER",
      sortKey: [
        {
          field: "opportunityId",
          sortOrder: "DESC",
        },
      ],
      statusId: [1, 57],
      // For now we will support only a week range from current date
      dateRange: {
        fromDate: new Date(new Date().setDate(new Date().getDate() - 8)),
        toDate: new Date(),
      },
      ownerOpportunityStageId: 97,
      opportunitySource: "PaymentLink",
    },
  },
  homePaymentEntities: {
    "home-payment-approved": {
      useDateFilter: true,
      dateFilter: "timeRange",
      name: "Approved Payments",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_APPROVED_BY_RCM_FOR_OPP",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
    "home-payment-rejected": {
      useDateFilter: true,
      dateFilter: "timeRange",
      name: "Rejected Payments",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_REJECTED_BY_RCM_FOR_OPP",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
    "home-payment-pending": {
      name: "Pending Payments",
      addAsFilter: true,
      caseSubTypeId: 6,
      key: "PAYMENT_PENDING_AT_RCM_FOR_OPP",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
    "home-payment-sent-back-to-ram": {
      name: "Payments Sent By RCM for correction",
      addAsFilter: true,
      caseSubTypeId: 6,
      useDateFilter: true,
      dateFilter: "timeRange",
      key: "PAYMENT_SENT_BACK_TO_RAM",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
    "payment-late-cheque-approval-pending": {
      name: "Payments - Late Cheque Approval Pending",
      addAsFilter: true,
      caseSubTypeId: 6,
      useDateFilter: true,
      dateFilter: "timeRange",
      key: "PAYMENT_LATE_CHEQUE_APPROVAL_PENDING",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
    "payment-credit-approval-pending": {
      name: "Payments - Credit Approval Pending",
      addAsFilter: true,
      caseSubTypeId: 6,
      useDateFilter: true,
      dateFilter: "timeRange",
      key: "PAYMENT_CREDIT_APPROVAL_PENDING",
      sortKey: [
        {
          field: "paymentsId",
          sortOrder: "DESC",
        },
      ],
    },
  },
  homeTermsheetEntities: {
    "home-pending-rcm-approval": {
      name: "Termsheets - Pending RCM Approval",
      addAsFilter: true,
      caseSubTypeId: 5,
      key: "TERMSHEET_PENDING_AT_RCM_FOR_OPP",
      sortKey: [
        {
          field: "termsheetId",
          sortOrder: "DESC",
        },
      ],
    },
    "home-approved-by-rcm": {
      name: "Termsheets - Approved By RCM this week",
      useDateFilter: true,
      dateFilter: "timeRange",
      addAsFilter: true,
      caseSubTypeId: 5,
      key: "TERMSHEET_APPROVED_BY_RCM_FOR_OPP",
      sortKey: [
        {
          field: "termsheetId",
          sortOrder: "DESC",
        },
      ],
    },
  },
  configUrls: [
    "/sapna/v1/reference-data",
    "/mystique/v1/reference-data/case-assign-status",
    `/mystique/v1/reference-data/case-reasons?filters=(reason==Login%20Activity,reason==Call%20Activity%20Logging,reason==${telesalesOpportunityConstants.NO_OZONTEL_CALLBACK})`,
  ],
  performanceBaseUrls: {
    account: "/mystique/v1/sac/key-search?salesAccountSelectorsArray",
    opportunity: "/mystique/v1/soc/key-search?salesOpportunitySelectorsArray",
  },
  liveListingsUrls: {
    RENT(accountUUID, pageSize, pageNumber) {
      let url = `${process.env.DOMAINS.KHOJ}api/v1/rent/index/filter?uuid=${accountUUID}&source=web`;
      if (pageSize) {
        url += `&results_per_page=${pageSize}`;
      }
      if (pageNumber) {
        url += `&p=${pageNumber}`;
      }
      return url;
    },
    BUY(accountUUID, pageSize, pageNumber) {
      let url = `${process.env.DOMAINS.SEARCH}api/v6/buy/index/filter?uuid=${accountUUID}&source=web`;
      if (pageSize) {
        url += `&results_per_page=${pageSize}`;
      }
      if (pageNumber) {
        url += `&p=${pageNumber}`;
      }
      return url;
    },
  },
  listingTypes: ["BUY", "RENT"],
  clientCategoryId: {
    // these client categoriesId are used for discount purposes
    Broker: 2,
    Owner: 3,
    TelesalesBroker: 7,
    TelesalesBuilder: 8,
  },
  builderDashboardTiles: ["preDiscovery", "linkSent", "paymentFailed"],
  pocTypes: {
    primary: "Primary",
    child: "Child",
    subUser: "SubUser",
  },
  clientTypeList: [
    "Owner",
    "Broker",
    "Builder",
    "Corporate", //  dummy value
    "Marketing Agency",
    "Financial Institution",
  ],
  clientTypeId: {
    Owner: 3,
    Builder: 1,
    Broker: 2,
  },
  caseType: {
    account: 9,
    opportunity: 10,
  },
  caseSubType: {
    ownerOpportunity: 13,
    opportunity: 3,
    tmOpportunity: 19,
    builderOpportunity: 22,
  },
  accountTypeList: ["Key", "Corporate"],
  opportunityStageBucket: {
    open: [1, 3, 4, 5, 8],
    closed: [2],
    lost: [6, 7],
  },
  telesalesOpportunityStatusIds: {
    LINK_SENT: 8,
  },
  paymentStatus: {
    sentBackToRam: [9, 16],
    pending: [1, 2, 3, 4, 5, 9, 12, 13, 20],
    rejected: [7, 8, 15, 16, 21, 22],
    rejectedForReviveRecollect: [7, 8],
    approved: [6, 15],
    rejectedAfterApproval: [11],
    refunded: [10, 14],
  },
  columns: {
    eventCityMappings: {
      eventId: {
        label: "Event Id",
        key: "eventId",
      },
      city: {
        label: "city",
        key: "cityUuid",
      },
    },
    digiliteVideoTourMapping: {
      packageId: {
        label: "Package Id",
        key: "packageId",
      },
      duration: {
        label: "Duration",
        key: "duration",
      },
      digiliteCount: {
        label: "Digilite Count",
        key: "digiliteCount",
      },
      videoTourCount: {
        label: "Video Tour Count",
        key: "videoTourCount",
      },
    },
    CityAgentMapping: {
      cityId: {
        label: "cityId",
        key: "cityId",
      },
      cityName: {
        label: "cityName",
        key: "cityName",
      },
      agentId: {
        label: "Agent Id",
        key: "agentId",
      },
      agentName: {
        label: "Agent Name",
        key: "agentName",
      },
    },
    approvalHistory: {
      user: {
        label: "User",
        key: "userName",
      },
      role: {
        label: "Role",
        key: "role",
      },
      status: {
        label: "Status",
        key: "status",
      },
      updatedAt: {
        label: "Last Activity On",
        key: "updatedAt",
        type: "datetime",
      },
    },
    leadsLocalityDetails: {
      pricebucket: {
        label: "Price Bucket",
        key: "rowspanKey",
      },
      localityName: {
        label: "Locality",
        key: "locality_name",
      },
      listingCount: {
        label: "Active Listings",
        key: "doc_count",
        padding: "5px 10px 0px 40px ",
      },
      leadCount: {
        label: "Leads",
        key: "lead_count",
        padding: "5px 10px 0px 18px ",
      },
    },
    npProjectDetails: {
      project: {
        label: "Project",
        key: "project_name",
      },
      locality: {
        label: "Locality",
        key: "locality_name",
      },
      leads: {
        label: "Leads",
        key: "lead_count",
      },
    },
    slotDetails: {
      payment: {
        label: "Payment Id",
        key: "paymentId",
        type: "link",
        getLink(p) {
          return `/payment/${p.paymentId}`;
        },
      },
      slotID: {
        label: "Slot ID",
        key: "slot_id",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      startDate: {
        label: "Start Date",
        key: "startDate",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      endDate: {
        label: "End Date",
        key: "endDate",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      status: {
        label: "Status",
        key: "message",
      },
    },
    slotDetailsDeveloper: {
      slotID: {
        label: "Slot ID",
        key: "slot_id",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      startDate: {
        label: "Start Date",
        key: "startDate",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      endDate: {
        label: "End Date",
        key: "endDate",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      status: {
        label: "Status",
        key: "message",
      },
    },
    package: {
      id: {
        label: "Id",
        key: "id",
      },
      packageFamilyName: {
        label: "Package Family",
        key: "packageFamilyName",
      },
      name: {
        label: "Package Name",
        key: "packageName",
      },
      rate: {
        label: "Rate",
        key: "ratePrice",
        type: "amount",
      },
      sale: {
        label: "Sales Price",
        key: "salesPrice",
        type: "amount",
      },
      discount: {
        label: "Discount",
        key: "discount",
        type: "nested",
        getValue(o) {
          if (o.ratePrice && o.salesPrice) {
            return constant.toAmount(o.ratePrice - o.salesPrice);
          }
          return "-NA-";
        },
      },
      duration: {
        label: "Duration",
        key: "promotedDays",
        type: "nested",
        getValue(v) {
          return v.promotedDays
            ? `${v.promotedDays} days`
            : v.validity
            ? `${v.validity} days`
            : "-NA-";
        },
      },
      quantity: {
        label: "Quantity",
        key: "quantity",
      },
      city: {
        label: "City",
        key: "cityName",
      },
      npSlotDetails: {
        label: "NP Slot Details",
        key: "packageHasNpProduct",
        value: "View",
        type: "link",
        getLink(p) {
          return p.packageHasNpProduct
            ? `/np-slot/${p.opportunityPackageMappingId}`
            : "";
        },
      },
    },
    maPackageDetails: {
      uuid: {
        label: "Profile uuid",
        key: "profile_uuid",
      },
      opportunityId: {
        label: "Opportunity Id",
        key: "opportunity_id",
        type: "function",
        getValue(v) {
          return v || "-NA-";
        },
      },
      contact: {
        label: "Contact limit / month",
        key: "contact_limit_per_month",
      },
      exclusive: {
        label: "Exclusive",
        key: "exclusive",
        type: "function",
        getValue(v) {
          if (v === true) return "Yes";
          else if (v === false) return "No";
          else return "-NA-";
        },
      },
      startDate: {
        label: "Start Date",
        key: "start_datetime",
        type: "date",
      },
      endDate: {
        label: "End Date",
        key: "end_datetime",
        type: "date",
      },
    },
    child: {
      legalName: {
        label: "Legal Name / Trade Name",
        key: "legalName",
      },
      accountId: {
        label: "Account ID",
        key: "accountId",
      },
      gstin: {
        label: "GST",
        key: "gstin",
      },
      pan: {
        label: "PAN",
        key: "pan",
      },
      tan: {
        label: "TAN",
        key: "tan",
      },
      registered: {
        label: "Registered/Unregistred",
        key: "isUnregisteredCustomer",
        type: "function",
        getValue: (isUnregistered) => {
          return isUnregistered ? "UnRegistered" : "Registered";
        },
      },
      billingAddress: {
        label: "Billing Address",
        key: "billingAddressString",
      },
      shippingAddress: {
        label: "Shipping Address",
        key: "shippingAddressString",
      },
      active: {
        label: "Active",
        key: "active",
        type: "boolean",
      },
    },
    history: {
      name: {
        label: "RAM",
        key: "agentName",
      },
      activityType: {
        label: "Activity Type",
        key: "activityType",
      },
      comment: {
        label: "Comment",
        key: "reasonName",
      },
      currentStatus: {
        label: "Previous Status/Stage",
        key: "currentStatusName",
      },
      nextStatus: {
        label: "Updated Status/Stage",
        key: "nextStatusName",
      },
      performedTime: {
        label: "Performed Time",
        key: "performedTime",
        type: "date",
      },
      nextFollowupTime: {
        label: "Next followUp Time",
        key: "fupTime",
        type: "datetime",
      },
      notes: {
        label: "Notes",
        key: "notes",
      },
    },
    contact: {
      name: {
        label: "Name",
        key: "name",
      },
      email: {
        label: "Email",
        key: "email",
        type: "email",
      },
      phone: {
        label: "Phone",
        key: "phone",
      },
      poc: {
        label: "POC Type",
        key: "pocType",
      },
      action: {
        label: "Actions",
        key: "action",
      },
      verification: {
        label: "Verification Status",
        key: "isVerified",
      },
    },
    contactShareInvoice: {
      name: {
        label: "Name",
        key: "name",
      },
      email: {
        label: "Email",
        key: "email",
        type: "email",
      },
      phone: {
        label: "Phone",
        key: "phone",
      },
      poc: {
        label: "POC Type",
        key: "pocType",
      },
    },
    ticketsList: {
      ticketId: {
        label: "Ticket Id",
        key: "key",
        type: "linkNewTab",
        getLink(o) {
          return o.site_link;
        },
      },
      summary: {
        label: "Subject",
        key: "summary",
      },
      status: {
        label: "Status",
        key: "status",
        type: "nested",
        getValue(o) {
          return `${o.status.name}`;
        },
      },
      assignedTo: {
        label: "Assigned To",
        key: "assignee",
        type: "nested",
        getValue(o) {
          return `${o.assignee.name}`;
        },
      },
      createdAt: {
        label: "Created At",
        key: "created_at",
        type: "date",
      },
      updatedAt: {
        label: "Updated At",
        key: "updated_at",
        type: "date",
      },
    },
    callingPOC: {
      name: {
        label: "Name",
        key: "name",
      },
      email: {
        label: "Email",
        key: "email",
        type: "email",
      },
      phone: {
        label: "Phone",
        key: "phone",
      },
      action: {
        label: "Actions",
        key: "action",
      },
    },
    ldcDetails: {
      legalEntityName: {
        label: "Legal Entity Name",
        key: "legalEntityName",
      },
      tanNumber: {
        label: "TAN Number",
        key: "tan",
      },
      threshold: {
        label: "Threshold",
        key: "thresholdLimit",
      },
      consumed: {
        label: "Consumed",
        key: "thresholdUtilized",
      },
    },
    sellerWalletDetails: {
      id: {
        label: "ID",
        key: "id",
      },
      paymentDate: {
        label: "Payemnt Date",
        key: "paymentDate",
        type: "datetime",
      },
      referenceNumber: {
        label: "Reference Number",
        key: "referenceNumber",
      },
      tdsPercentage: {
        label: "TDS Percentage",
        key: "tdsPercentage",
      },
      amount: {
        label: "Amount",
        key: "amount",
        type: "amount",
        textColor: "green",
      },
      amountExcludingTds: {
        label: "Amount Excluding TDS",
        key: "amountExcludingTds",
        type: "amount",
      },
      remainingAmount: {
        label: "Remaining Amount",
        key: "remainingAmount",
        type: "amount",
        textColor: "blue",
      },
    },
  },
  getUserIdByRole: (roles, userId) => {
    const roleMapping = {
      LOOKUP_MASTER_DATA: 9493307,
    };

    for (const role in roleMapping) {
      if (roles?.includes(role)) {
        return roleMapping[role];
      }
    }

    return userId;
  },
  masterRoles: {
    housing: [
      {
        roleId: 1040,
        name: "LOOKUP_MASTER_DATA",
        value: "LOOKUP_MASTER_DATA",
      },
      {
        roleId: 100,
        name: "Housing Sales Manager",
        value: "Housing Sales Manager",
      },
      {
        roleId: 101,
        name: "Housing Sales Agent",
        value: "Housing Sales Agent",
      },
      {
        roleId: 1001,
        name: "Ap Admin",
        value: "Ap Admin",
      },
      {
        roleId: 134,
        name: "TENANT_MONETIZATION_AGENT",
        value: "TENANT_MONETIZATION_AGENT",
      },
      {
        roleId: 1008,
        name: "Lookup View",
        value: "LOOKUP VIEW",
      },
      {
        roleId: 1013,
        name: "Lead Delivery SPOC",
        value: "Lead Delivery SPOC",
      },
      {
        roleId: 1043,
        name: "Audience Maximizer Agent",
        value: "Audience Maximizer Agent",
      },
      {
        roleId: 1044,
        name: "Lead Auditor",
        value: "Lead Auditor",
      },
      {
        roleId: 1054,
        name: "Client Acquistion Team",
        value: "CLIENT_ACQUISITION_TEAM",
      },
    ],
    makaan: [
      {
        roleId: 117,
        name: "Makaan Sales Agent",
        value: "MakaanSalesAgent",
      },
      {
        roleId: 118,
        name: "Makaan Sales Manager",
        value: "MakaanSalesManager",
      },
    ],
  },
  profileTypeOwner: "User" /** together with is_owner=true flag */,
  masterDesignations: [
    {
      designationId: 29,
      label: "AM",
    },
    {
      designationId: 30,
      label: "SAM",
    },
    {
      designationId: 31,
      label: "Key Accounts",
    },
    {
      designationId: 32,
      label: "TL",
    },
    {
      designationId: 33,
      label: "ZM",
    },
    {
      designationId: 34,
      label: "RBM",
    },
    {
      designationId: 35,
      label: "RBH",
    },
    {
      designationId: 36,
      label: "AH",
    },
    {
      designationId: 37,
      label: "CH",
    },
    {
      designationId: 38,
      label: "TM",
    },
    {
      designationId: 39,
      label: "STM",
    },
    {
      designationId: 40,
      label: "NSH",
    },
    {
      designationId: 41,
      label: "CBO",
    },
  ],
  makaanCitiesTierList: [1, 2, 3],
  makaanHostNameList: [
    "spacex.makaan.com",
    "spacex.makaan-ws.com",
    "lookup.makaan.com",
  ],
  monthsArray:
    "January_February_March_April_May_June_July_August_September_October_November_December".split(
      "_"
    ),
  isBuilderBroker: (clientTypeId) => [1, 2].includes(clientTypeId),
  isEmailValid: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
  },
  isValidPhone: (number) => {
    const re = /^\d{10}$/;
    return re.test(number.trim());
  },

  hasWhiteSpace: (string) => {
    return /\s/g.test(string);
  },

  isValidName: (string) => {
    string = string.replace(/\s/g, "");
    const re = /^[^_\W]*$/g;
    return re.test(string.trim());
  },
  isStringEmpty: (string) => {
    return !string || (typeof string === "string" && !string.trim());
  },
  toUpperCaseWords: (string) => {
    return string.replace(/\w+/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  },
  toBooleanYesNo: (bool) => {
    return bool === true ? "Yes" : "No";
  },
  // eslint-disable-next-line react/display-name
  toAmount: (string) => {
    return string || string === 0 ? (
      <span>&#8377; {`${parseFloat(string).toLocaleString("hi-IN")}`}</span>
    ) : (
      string
    );
  },
  formatInrCurrency: (amount) => {
    const suffix = amount.match(/Lakhs|Cr/)?.[0];
    const numberPart = parseFloat(amount);

    if (numberPart || numberPart === 0) {
      const formattedNumber = `₹ ${numberPart.toLocaleString("hi-IN")}`;
      return suffix ? `${formattedNumber} ${suffix}` : formattedNumber;
    }
    return amount;
  },
  getRoundOfValue: (value, numOfDigits) => {
    return (
      Math.round(numOfDigits * 10 * value.toFixed(numOfDigits)) / (numOfDigits * 10)
    );
  },
  getDateDifferenceInYearsAndMonths: (d1, d2) => {
    let m = (d1.getFullYear() - d2.getFullYear()) * 12;
    m += d1.getMonth();
    m -= d2.getMonth();
    return (
      (Math.floor(m / 12) === 0 ? "" : Math.floor(m / 12) + "Year ") +
      (m % 12 === 0 ? "" : (m % 12) + "Month")
    );
  },
  epochToDate: (epoch) => {
    if (!epoch) return "";
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(epoch).toLocaleString("en-GB", options).split(",")[0];
  },
  epochToDateTime: (epoch) => {
    if (!epoch) return "";
    const options = { timeZone: "Asia/Kolkata" };
    return new Date(epoch).toLocaleString("en-GB", options);
  },
  snakeCaseToCamelCase: (string) => {
    return string.replace(/_\w/g, (word) => word[1].toUpperCase());
  },
  camelCaseToTitle: (string) => {
    return string.replace(/([A-Z0-9])/g, " $1").replace(/^./, (str) => {
      return str.toUpperCase();
    });
  },
  camelCaseToSnakeCase: (string) => {
    return string.replace(/([A-Z])/g, "_$1").toLowerCase();
  },
  isAlphaNumeric: (string) => {
    return /^([a-zA-Z0-9,-/ ]+)$/.test(string);
  },
  hasSpecialCharacters: (string) => {
    return !/^[-#_,&.'/()A-z0-9]*((-|\s)*[-#_,&.'/()A-z0-9])*$/g.test(string);
  },
  getBreadcrumArray: (
    { accountId, opportunityId, termsheetId, milestoneId },
    params,
    type
  ) => {
    const steps = [];
    if (accountId) {
      steps.push({
        label: `Account#${accountId}`,
        link: `/account/${accountId}`,
      });
    }
    if (opportunityId) {
      steps.push({
        label: `Opportunity#${opportunityId}`,
        link: `/opportunity/${opportunityId}`,
      });
    }
    if (termsheetId) {
      steps.push({
        label: `Termsheet#${termsheetId}`,
        link: `/termsheet/${termsheetId}`,
      });
    }
    if (milestoneId) {
      steps.push({
        label: `Milestone#${milestoneId}`,
        link: `/termsheet/${termsheetId}`,
      });
    }
    if (type) {
      steps.push({
        label: `${constant.toUpperCaseWords(type)}#${params[`${type}Id`]}`,
      });
    }
    return steps;
  },
  valueToId: (obj) => {
    const result = {};
    Object.keys(obj).forEach((key) => {
      result[obj[key]] = key;
    });
    return result;
  },
  getErrorMessage: (err, customAuthMsg = "") => {
    let errorMessage;
    if (err) {
      if (err.response) {
        const { status, data } = err.response;
        if ([400, 401, 422, 500].indexOf(status) !== -1) {
          errorMessage =
            (status === 401 && customAuthMsg) ||
            (data && data.error && data.error.msg) ||
            (status === 422 && data && data.api_errors && data.api_errors.details) ||
            (status === 500 && data && data.api_errors && data.api_errors.details) ||
            (data && data.api_errors && data.api_errors.display_phrase) ||
            (data && data.apiErrors && data.apiErrors.displayPhrase) ||
            (data &&
              data.message &&
              Array.isArray(data.message) &&
              data.message[0]) ||
            (data &&
              data.message &&
              typeof data.message === "string" &&
              data.message) ||
            err.message;
        }
      } else if (err.message) return err.message;
    }
    return errorMessage || "Alas! Some error occurred.";
  },
  getRequestQueryParamsFromObj: (paramsObj) => {
    return Object.keys(paramsObj)
      .map((key) => `${key}=${paramsObj[key]}`)
      .join("&");
  },

  isLSQEnabled: (agentCityId, clientTypeId, agentTypeId) => {
    //const lsqLaunchCityIds = ["12", "13"], // pt type city ids array. 12 -> hyderabad,13 --> Indore
    const lsqLaunchClientTypeIds = [1, 2], // 1:builder, 2: broker
      lsqLaunchAgentTypeIds = [1]; // 1:fos
    return !(
      // agentCityId &&
      // lsqLaunchCityIds.includes(agentCityId) &&
      (
        clientTypeId &&
        lsqLaunchClientTypeIds.includes(clientTypeId) &&
        agentTypeId &&
        lsqLaunchAgentTypeIds.includes(agentTypeId)
      )
    );
  },
  truthFunction: () => true,
  autocompleteListStyle: { maxHeight: 250, overflow: "auto" },
  customPackageFamilies: {
    campaignPackage: {
      name: "Winter Bonanza",
      id: 1000,
      realId: 43,
      applicableRegion: ["South West Delhi"],
      extraDays: 60,
      fieldName: "promotedDays",
      excludeOptions: [180],
      customKey: "sellThroughCampaign",
    },
  },
  HOUSING_OWNER_CASESUBTYPEID: 13,
  HOUSING_BROKER_CASESUBTYPEID: 3,
  HOUSING_BUILDER_CASESUBTYPEID: 22,
  approvalStatusTypes: getMapFromList([
    "NotRequired",
    "Required",
    "PendingApproval",
    "Approved",
    "Rejected",
  ]),
  approvalTypes: {
    Discount: "Discount",
    CreditApproval: "CreditApproval",
    DealDropped: "DealDropped",
    FreeTrial: "FreeTrial",
    Refund: "Refund",
    LateCheckDeposition: "LateCheckDeposition",
    PriceRestoration: "PriceRestortion",
    DelayedActivation: "DelayedActivation",
  },
  opportunityTypeId: {
    FreeTrial: 8,
  },
  paymentTypeId: {
    LateCheckDeposition: 3,
    Refund: 12,
    Approved: 6,
  },
  micrositeColumns: {
    id: {
      label: "Slot ID",
      key: "slotId",
      type: "link",
      getLink(obj) {
        return `/microsite/${obj.slotId}`;
      },
    },
    entityId: {
      label: "Project ID",
      key: "entityId",
    },
    sellerId: {
      label: "Seller Id",
      key: "sellerId",
    },
    cityId: {
      label: "City Id",
      key: "ploygonId",
    },
    startDate: {
      label: "Start Date",
      key: "startDate",
      type: "date",
    },
    endDate: {
      label: "End Date",
      key: "endDate",
      type: "date",
    },
    status: {
      label: "Status",
      key: "status",
    },
  },
  extraCities: [
    {
      uuid: "b16f5854a452e2896a36",
      name: "Bhopal",
    },
    {
      uuid: "8678f1084c28393785d5",
      name: "Chandigarh",
    },
    {
      uuid: "60b8ccfaa32e72861ec1",
      name: "Coimbatore",
    },
    {
      uuid: "110d08df03442399cd8d",
      name: "Goa",
    },
    {
      uuid: "03d6e2fba020d921c748",
      name: "Indore",
    },
    {
      uuid: "9012656d290ec65ef174",
      name: "Jaipur",
    },
    {
      uuid: "241b6641fe4343119982",
      name: "Lucknow",
    },
    {
      uuid: "4ec3d9cf40c1b278e347",
      name: "Nagpur",
    },
    {
      uuid: "0383bdf1fc8f5060c07b",
      name: "Nashik",
    },
    {
      uuid: "0b71e081339939e6a43a",
      name: "Vadodara",
    },
    {
      uuid: "864b82249cd2954f18e0",
      name: "Visakhapatnam",
    },
    {
      uuid: "cebbf61a0e1f50cf7d62",
      name: "Zirakpur",
    },
    {
      uuid: "54f5acdabbd66f3f9a2a",
      name: "Mohali",
    },
  ],
  accountColumns: {
    id: {
      label: "ID",
      key: "accountId",
      type: "link",
      getLink(o) {
        return `/account/${o.accountId}`;
      },
    },
    accountName: {
      label: "Account Name",
      key: "accountName",
    },
    accountUUID: {
      label: "Account UUID",
      key: "housingProfileId",
    },
    city: {
      label: "City",
      key: "cityName",
    },
    owner: {
      label: "Owner",
      key: "agentName",
    },
    contacts: {
      label: "Contacts",
      key: "pocContactNumbers",
      type: "function",
      getValue(pocContactNumbers) {
        return pocContactNumbers && pocContactNumbers.length
          ? pocContactNumbers.join(", ")
          : "-";
      },
    },
    totalDealValue: {
      label: "Total Deal Value",
      key: "dealValue",
      type: "amount",
    },
    totalCollectedAmout: {
      label: "Total Collected Amount",
      key: "collectedAmount",
      type: "amount",
    },
    closedOpportunites: {
      label: "Closed Opportunities",
      key: "closedOpportunities",
    },
    activeOpportunities: {
      label: "Active Opportunities",
      key: "activeOpportunities",
    },
    clientStatus: {
      label: "Client Status",
      key: "clientStatusId",
      type: "function",
      getValue(clientStatusId) {
        return clientStatusIdMap[clientStatusId] || "-";
      },
    },
    createdAt: {
      label: "Created At",
      key: "createdAt",
      type: "date",
    },
    lastModifiedAt: {
      label: "Last Modified",
      key: "updatedAt",
      type: "date",
    },
    walletBalance: {
      label: "Present Wallet Balance",
      key: "accountWalletAmount",
      type: "balance",
      getBalanceLink(o) {
        return `/account/${o.accountId}`;
      },
    },
  },
  incentiveRuleFrequency: ["Monthly", "Quarterly"],
  incentiveMinMaxRelaxedDesignations: [31, 35, 36, 37],
  months: {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  },
  SIGNED_OFF: "Signed Off",
  FINANCE_APPROVED: "Finance Approved",
  approvalTypes_entityTypeIdsMap: {
    LateCheckDeposition: 6, // Payment - EntityTypeId=6
    CreditApproval: 6, // Payment - EntityTypeId=6
    Discount: 3, // Opportunity - EntityTypeId=3
    FreeTrial: 3, // Opportunity - EntityTypeId=3
    Refund: 9, // Refund - EntityTypeId=9
  },
  paymentModeIds: {
    creditApproval: 7,
    cheque: 1,
  },
  HOUSING_OWNER_SOURCE: "housingowner",
  HOUSING_SELLER: "housingseller",
  HOUSING_PRODUCT: "housingproduct",
  HOUSING_LOOKUP: "lookup",
  TENANT_MONETIZATION_SOURCE: "tenantmonetization",
  PAYMENT_LINK_SOURCE: "paymentlink",
  PAYMENT_DONE: "payment_done",
  SERVICE_CALL_OPPORTUNITY_TYPE: "ServiceCall",
  RENEWAL_CALL_OPPORTUNITY_TYPE: "RenewalCall",
  PRE_DISCOVERY: "pre_discovery",
  DISCOVERY: "discovery",
  BUY_INITIATION: "buy_initiation",
  ORDER_CONFIRMATION: "order_confirmation",
  PAYMENT_FAILED: "payment_failed",
  BROKER_OPPORTUNITY_STAGES: [
    "pre_discovery",
    "discovery",
    "buy_initiation",
    "order_confirmation",
    "payment_failed",
  ],
  checkValidValueByRegex: (type, valueToCheck) => {
    let regex;
    switch (type.toUpperCase()) {
      case "GST":
        regex = /(^\d{2}[A-Za-z]{5}\d{4}[A-Za-z]\d[A-Za-z][^_\W]$)/g;
        break;
      case "AADHAR":
        regex = /(^\d{12}$)/g;
        break;
      case "PAN":
        regex = /(^[A-Za-z]{5}\d{4}[A-Za-z]$)/g;
        break;
      case "CIN":
        regex = /(^[A-Za-z]\d{5}[A-Za-z]{2}\d{4}[A-Za-z]{3}\d{6}$)/g;
        break;
      default:
        return false;
    }
    const found = valueToCheck.match(regex);
    return found ? found.length > 0 : false;
  },
  ADMIN_TAB: "Admin",
  PRODUCT_MANAGEMENT: "Product Management",
  USER_MANAGEMENT: "User Management",
  LOC_AGENT_MAPPING: "Locality Agent Mapping",
  MANAGE_REGIONS: "Manage Regions",
  SALES_INCENTIVE: "Sales Incentive",
  DOWNLOAD_REPORTS: "Download Reports",
  DISCOUNT_APPROVAL_MATRIX: "Discount Approval Matrix",
  MERGE_ACCOUNTS: "Merge Accounts",
  MERGE_ACCOUNTS_IP: "Merge Accounts (IP)",
  NETSUITE_PUSH_DATA: "Netsuite Push Data",
  NETSUITE_EVENT_TRACKING: "Netsuite Event Tracking",
  MONETIZABLE_CITIES: "Monetizable Cities",
  SALES_CLOSURE_DATE: "Sales Closure Date",
  OTHER_MATRICES: "Other Matrices",
  TSTL: "Tele-Sales Team Leader",
  TSPO: "Tele-Sales Process Owner",
  TSA: "Tele-Sales Agent",
  BTS: "Broker Tele-Sales",
  OOA: "Owner Online Assist",
  OFA: "Owner Field Assist",
  HOM: "Housing Owner Manager",
  BTTL: "Broker Tele-Sales Team Lead",
  BTPM: "Broker Tele-Sales Process Manager",
  TOTAL_SC_OPP: "Total SC Opp",
  SALES_CONN: "Sales Connect",
  ALERT_DEPT_EMPTY_FIELD: "Please Select Department",
  ALERT_BRANCH_EMPTY_FIELD: "Please Select Branch",
  ALERT_DOMAIN_EMPTY_FIELD: "Please Select Domain",
  ALERT_ENTITY_EMPTY_FIELD: "Please Select Entity Name",
  ALERT_EMPTY_ENTITY_ID: "Please Enter Entity ID",
  ALERT_FIRST_COMMA: "Please use comma after value",
  ALERT_LEADING_ZEROS: "Please enter digits without leading zeros",
  ALERT_LAST_COMMA: "Please remove comma after last value",
  ALERT_VALID_MAX_LIMIT: "Please enter not more than 100 IDs",
  ALERT_TWO_COMMA: "Please enter not more than 1 consecutive comma",
  ALERT_VALID_ENTITY_FIELD: "Please enter valid digit with max size 10",
  DOMAIN_MENUITEMS: ["Housing", "PropTiger"],
  ENTITY_MENUITEMS: [
    "Customer",
    "Invoice",
    "Payment",
    "Credit Note",
    "Receipt Voucher",
    "Refund Voucher",
  ],
  INVOICE_ALREADY_SENT:
    "Invoice already sent on email, if you want to re-send please select POC and click on resend button",
  INVOICE_TO_SEND:
    "If you want to send invoice on client email then please select POC and click on send button",
  INACTIVE_NOTIFICATION:
    "This account is currently in Inactive/Deleted state in Lookup",
  CONFIRM_NOTIFICATION:
    "This account is currently in Inactive stage or is deleted. Please connect with BRM team for the same along with Lookup Account ID for more details",
  CONFIRM_NETSUITE_NOTIFICATION:
    "Please confirm enabling netsuite, once enabled this option cannot be disabled",
  CONFIRM_DIALOG_HEADER: "Alert Inactive Profile",
  CONFIRM_NETSUITE_DIALOG_HEADER: "Confirm Enabling",
  DISABLE_ADD_NETSUITE:
    "Adding not allowed as Netsuite Flag is enabled for this package",
  SUB_BROKER_HEADER: "Alert Sub-Broker Profile",
  SUB_BROKER_NOTIFICATION: `This is a sub-broker account linked to a parent account. You cannot create a term-sheet, collect payment, or activate a slot here.\n
  To proceed, please choose one of the following options:\n
  1- Unlink this account from the parent account, OR\n
  2- Create and close the opportunity at the parent account level, OR\n
  3- Use an alternate mobile number for this broker to create the opportunity.`,
  SUB_BROKER_ALERT_MSG: `This is a sub-broker account, opportunities cannot be logged here.Kindly place the order under the parent account.`,

  getSubBrokerDialogContent: () => {
    return {
      title: constant.SUB_BROKER_HEADER,
      subTitle: constant.SUB_BROKER_NOTIFICATION,
    };
  },
  getInactiveDialogContent: () => {
    return {
      title: constant.CONFIRM_DIALOG_HEADER,
      subTitle: constant.CONFIRM_NOTIFICATION,
    };
  },
  getEditNetsuiteMessage: (fields) =>
    `Editing of ${fields?.join(
      ", "
    )} field(s) only allowed as Netsuite Flag is enabled for this package`,
  DEVELOPER_EXCLUSIVE_REQUIRED:
    "This project is Builder exclusive - No agent is allowed to activate on this project",
  DISABLE_DELETE_NETSUITE:
    "Deleting not allowed as Netsuite Flag is enabled for this package",
  DELAYED_ACTIVATION_ALERT:
    "Your Combo is exceeding the allowed validity period and will require approval. Please adjust the dates or seek delayed activation approval on the next step",
  swapKeyValueMap: (map) => {
    return Object.keys(map).reduce((acc, key) => {
      const newKey = map[key];
      acc[newKey] = key;
      return acc;
    }, {});
  },

  REDIAL_COMMENT: 279,
  additionalAdminRoles: [
    "Tele-Sales Agent",
    "Tele-Sales Team Leader",
    "Tele-Sales Process Owner",
    "Owner Online Assist",
    "Owner Field Assist",
    "Broker Tele-Sales",
    "Broker Tele-Sales Team Lead",
    "Broker Tele-Sales Process Manager",
    "Broker Tele-Sales Business Manager",
    "Builder Tele-Sales",
    "Builder Tele-Sales Team Lead",
    "Builder Tele-Sales Process Manager",
    "Builder Tele-Sales Business Manager",
  ],
  additionalLkpAdminRoles: ["Housing Owner Manager"],
  additionalRoleValueMapping: {
    "Tele-Sales Agent": ["Housing Sales Agent", "Housing Owner Agent", "CRM USER"],
    "Tele-Sales Team Leader": [
      "Housing Owner Agent",
      "Housing Sales Manager",
      "CRM USER",
      "HOUSING_SALES_TEAM_LEADER",
    ],
    "Tele-Sales Process Owner": [
      "Housing Owner Agent",
      "Housing Sales Manager",
      "CRM USER",
      "HOUSING_SALES_PROCESS_MANAGER",
    ],
    "Owner Online Assist": ["PHOENIX_AGENT"],
    "Owner Field Assist": ["PHOENIX_FIELD_AGENT"],
    "Housing Owner Manager": [
      "Housing Owner Agent",
      "Housing Sales Manager",
      "Admin",
      "CRM USER",
    ],
    "Broker Tele-Sales": ["CRM USER", "Broker Tele Sales"],
    RCM_USER: ["RCM_USER"],
    "Broker Tele-Sales Team Lead": [
      "Broker Tele Sales Team Lead",
      "CRM USER",
      "Broker Tele Sales",
    ],

    "Broker Tele-Sales Process Manager": [
      "Broker Tele Sales Process Manager",
      "CRM USER",
      "Broker Tele Sales",
    ],
    "Broker Tele-Sales Business Manager": [
      "Broker Tele Sales Business Manager",
      "CRM USER",
      "Broker Tele Sales",
    ],
    "Builder Tele-Sales": ["CRM USER", "Builder Tele Sales"],

    "Builder Tele-Sales Team Lead": [
      "Builder Tele Sales Team Lead",
      "CRM USER",
      "Builder Tele Sales",
    ],

    "Builder Tele-Sales Process Manager": [
      "Builder Tele Sales Process Manager",
      "CRM USER",
      "Builder Tele Sales",
    ],
    "Builder Tele-Sales Business Manager": [
      "Builder Tele Sales Business Manager",
      "CRM USER",
      "Builder Tele Sales",
    ],
  },
  PAYMENT_GATEWAY: "Payment Gateway",
  GST_PERCENTAGE: 18,
  Zunheboto_city_name: "Zunheboto",
  Zunheboto_uuid: "4afe0071e5fe5380cd9e",
  mumbai_all_city_name: "Mumbai All",
  mumbai_all_uuid: "a0fd32816f73961748cf",
  showPriceApprovalButton: false,
  invalidPIN: "PIN is not valid",
  isValidPINCode: (str) => {
    const regex = /^[1-9]{1}[0-9]{5}$/g;
    return regex.test(str);
  },
  MANDATORY_PIN_CODE_ADD_PAYMENT: "PIN Code is mandatory for adding Payment",
  getCurrentMonthAsyyyymm: (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      // day = '01',
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    return `${[year, month].join("-")}`;
  },
  getDefaultRenewalReportFilter: () => ({
    profileType: ["Rent/Resale", "New Project", "Premier Listings Broker"],
    sellerType: ["Broker", "Builder", "Owner"],
    month: constant.getMonthsList(),
    renewalType: [
      "30D Renewal",
      "30-45D Renewal",
      "45-60D Renewal",
      "60-90D Renewal",
      ">90D Renewal",
    ],
    elapseFlag: [
      "Lapsed_7_days",
      "Lapsed_8_to_15_days",
      "Lapsed_16_to_30_days",
      "Lapsed_31_to_60_days",
      "Lapsed_61_to_180_days",
    ],
    upForRenewal: [
      "Running and Will expire in next 7 days",
      "Running and Will expire in next 8 to 15 days",
      "Running and Will expire in next 15 to 30 days",
      "Running and Will expire in next 30 to 60 days",
      "Running and Will expire in next 60 to 180 days",
    ],
  }),
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  getMonthsList: () => {
    const monthsList = [];
    const monthNames = constant.monthNames;
    // starting month and year taken from tableau report which is October 2018
    const startYear = 2018;
    const startMonth = 9;
    const startDate = new Date(startYear, startMonth, 1);

    let currentDate = new Date();
    currentDate = addMonths(currentDate, 3);

    let startingReferenceDate = startDate;
    while (startingReferenceDate <= currentDate) {
      const startingReferenceMonth = startingReferenceDate.getMonth();
      const startingReferenceYear = startingReferenceDate.getFullYear();
      monthsList.push(
        // e.g January 2018
        `${monthNames[startingReferenceMonth]} ${startingReferenceYear}`
      );
      startingReferenceDate = addMonths(startingReferenceDate, 1);
    }

    return monthsList.reverse();
  },
  getDateAsYYYYMMDDhhmmss: (monthAndYear) => {
    const [month, year] = monthAndYear.split(" ");
    let monthIndex = constant.monthNames.indexOf(month);
    // adjust month index by 1, so 01 is January, so on...
    monthIndex = "" + (monthIndex + 1);
    if (monthIndex.length < 2) monthIndex = "0" + monthIndex;
    return `${[year, monthIndex, "01"].join("-")} 00:00:00`;
  },
  convertYYYYMMDDhhmmssToMonth: (dateWithhhmmss) => {
    const [date] = dateWithhhmmss.split(" ");
    const [year, month] = date.split("-");
    const adjustedMonthIndex = Number(month) - 1;
    const monthName = constant.monthNames[adjustedMonthIndex];

    return `${monthName} ${year}`;
  },
  convertMonthlySummaryToMap: (data = [], monthKeys = []) => {
    const map = {};
    monthKeys.forEach((key) => (map[key] = {}));
    data.forEach((item) => {
      const { month } = item;
      const monthKey = constant.convertYYYYMMDDhhmmssToMonth(month);
      if (map[monthKey]) {
        map[monthKey] = item;
      }
    });
    return map;
  },
  convertAgentWiseSummaryToMap: (data = []) => {
    const tableData = [];
    data.forEach((item) => {
      let row;
      const summaryMap = {};
      const { summary, ...otherData } = item;
      summary.forEach(({ month, renewal }) => {
        const monthKey = constant.convertYYYYMMDDhhmmssToMonth(month);
        summaryMap[monthKey] = { renewal };
      });
      row = { ...otherData, summaryMap };
      tableData.push(row);
    });
    return tableData;
  },
  convertRenewalAlertToMap: (data = []) => {
    const tableData = [];
    data.forEach((item) => {
      let row;
      const { elapseFlag, upForRenewal, ...otherData } = item;
      const alertMap = {};
      alertMap["elapseFlag.count"] = elapseFlag.count;
      alertMap["elapseFlag.value"] = elapseFlag.value;
      alertMap["elapseFlag.cases"] = elapseFlag.cases;
      alertMap["upForRenewal.count"] = upForRenewal.count;
      alertMap["upForRenewal.value"] = upForRenewal.value;
      alertMap["upForRenewal.cases"] = upForRenewal.cases;
      row = { ...otherData, alertMap };
      tableData.push(row);
    });
    return tableData;
  },
  addUpComingRenewals: (data = []) => {
    let totalCount = 0,
      allCases = [];
    data.forEach((item) => {
      const {
        upForRenewal: { count, cases },
      } = item;
      totalCount += count;
      allCases = allCases.concat(cases);
    });
    return { count: totalCount, cases: allCases };
  },
  convertChartSummaryToTableArray: (data = [], monthWiseAverages) => {
    const tableArray = [];
    data.forEach((item) => {
      const { month, broker, builder, owner } = item;
      // developer is average of builder and owner
      const monthWithYear = constant.convertYYYYMMDDhhmmssToMonth(month);
      const { nationalAvg = 0 } = monthWiseAverages[month] || {};
      tableArray.push([
        monthWithYear,
        Number(broker.toPrecision(3)),
        Number(builder.toPrecision(3)),
        Number(owner.toPrecision(3)),
        /*  cityAvg, */
        nationalAvg,
      ]);
    });
    return tableArray;
  },
  performanceMapping: {
    "Housing Owner Manager": "Admin",
    "Tele-Sales Process Owner": "HOUSING_SALES_PROCESS_MANAGER",
    "Tele-Sales Team Leader": "HOUSING_SALES_TEAM_LEADER",
    "Tele-Sales Agent": "Housing Owner Agent",
    "Broker Tele-Sales Process Manager": "Broker Tele Sales Process Manager",
    "Broker Tele-Sales Team Lead": "Broker Tele Sales Team Lead",
    "Broker Tele-Sales": "Broker Tele Sales",
    "Builder Tele-Sales Process Manager": "Builder Tele Sales Process Manager",
    "Builder Tele-Sales Team Lead": "Builder Tele Sales Team Lead",
    "Builder Tele-Sales": "Builder Tele Sales",
  },
  performanceTableFilters: ["Today", "MTD"],
  callingTitles: [
    "Calls Attempted",
    "Calls Connected",
    "Total call-time",
    "Average talk-time",
    "Calls Not Connected",
  ],
  chartingScript: "https://www.gstatic.com/charts/loader.js",
  housingSellerBaseUrls: {
    prod: "https://seller.housing.com",
    staging: "https://seller-k8s.staging.housing.com",
  },
  getStartCase: (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  },
  convertNumberToDaysMonthsYear: (diff) => {
    // The string we're working with to create the representation
    let str = "";
    // Map lengths of `diff` to different time periods
    const values = [
      [" year", 365],
      [" month", 30],
      [" day", 1],
    ];

    // Iterate over the values...
    for (let i = 0; i < values.length; i++) {
      let amount = Math.floor(diff / values[i][1]);

      // ... and find the largest time value that fits into the diff
      if (amount >= 1) {
        // If we match, add to the string ('s' is for pluralization)
        str += amount + values[i][0] + (amount > 1 ? "s" : "") + " ";

        // and subtract from the diff
        diff -= amount * values[i][1];
      }
    }

    return str;
  },
  numberWithCommas: (x) => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  },
  activeCampaigns: "Active Campaigns",
  createCampaigns: "Create Campaign",
  campaignTypes: {
    normal: "Normal",
    manualRechurn: "Manual Rechurn",
    manual: "Manual",
  },
  toPrecision3: (num) => {
    let numToConvert = num;
    if (typeof num !== "number") {
      numToConvert = Number(numToConvert);
    }

    return !Number.isInteger(numToConvert)
      ? numToConvert.toPrecision(3)
      : numToConvert;
  },
  chartDateParser: (date) => {
    if (!date) {
      return null;
    }
    const day = date.substring(6);
    return day;
  },
  percentageRepresentationOption(dt, row, formatPercent, val) {
    let total = dt.getValue(row, 1) + dt.getValue(row, 2);
    total === 0 && (total = 1);
    return formatPercent.formatValue(dt.getValue(row, val) / total);
  },
  intToCroresConverter: (num) => {
    const lakh = 100000;
    const crore = 10000000;

    if (num < lakh) {
      return num.toString();
    } else if (num < crore) {
      return (num / lakh).toFixed(2) + " Lakhs";
    } else {
      return (num / crore).toFixed(2) + " Cr";
    }
  },
  transformDisbursalRanges: (possibleDisbursalRanges) => {
    const { intToCroresConverter } = constant;
    return (possibleDisbursalRanges || []).map((range, index) => {
      const obj = {
        key: index + 1,
      };
      if ("to" in range && "from" in range) {
        obj.string = `${intToCroresConverter(range.from)} - ${intToCroresConverter(
          range.to
        )}`;
        obj.to = range.to;
        obj.from = range.from;
      } else if ("to" in range) {
        obj.string = `<${intToCroresConverter(range.to)}`;
        obj.to = range.to;
      } else if ("from" in range) {
        obj.string = `>=${intToCroresConverter(range.from)}`;
        obj.from = range.from;
      }
      if ("possible_percentage_shares" in range) {
        obj.percentageShares = range.possible_percentage_shares;
      }
      if ("percentage_share" in range) {
        obj.percentage_share = range.percentage_share;
      }
      return obj;
    });
  },
  salesValueConverter: (value, decimal = 3) => {
    const num = parseFloat(value / 100000);
    return value
      ? +(Math.round(num + "e+" + decimal) + "e-" + decimal)
      : `0.${"0".repeat(decimal)}`;
  },
  callingTimeConverter: (value) => {
    const hours = Math.floor(value);
    const minutesInFloatingValues = parseFloat((value - hours) * 60);
    const minutes = Math.floor(minutesInFloatingValues);
    const seconds = Number((minutesInFloatingValues - minutes) * 60).toFixed(0);
    const time = `${constant.addLeadingZeros(hours)}:${constant.addLeadingZeros(
      minutes
    )}:${constant.addLeadingZeros(seconds)}`;
    return time;
  },
  addLeadingZeros: (value) => (value > 9 ? value : "0" + value),
  callingTimeTitleHeaders(titles) {
    return titles.map((title) =>
      title.includes("time") ? `${title}\n(hh:mm:ss)` : title
    );
  },
  //for site takeover
  productTypeByIdMap: {
    Event: 6,
    Test: 8,
    "MG converted": 1,
    Trail: 9,
    Default: 10,
  },
  isEmptyObject: (obj) => {
    return (
      obj && // 👈 null and undefined check
      Object.keys(obj).length === 0 &&
      obj.constructor === Object
    );
  },
  performanceDropdownFilters: (role) => {
    switch (role) {
      case constant.HOM:
        return constant.performanceFilters[1];
      case constant.TSPO:
        return constant.performanceFilters[2];
      case constant.TSTL:
        return constant.performanceFilters[3];
      case constant.BTPM:
        return constant.performanceFilters[6];
      case constant.BTTL:
        return constant.performanceFilters[7];
      case "admin-owner":
        return constant.performanceFilters[1];
      case "admin-broker":
        return constant.performanceFilters[5];
      default:
        return null;
    }
  },
  userRoleMappingForStatus: (role) => {
    switch (role) {
      case constant.HOM:
        return constant.performanceFilters[0];
      case constant.TSPO:
        return constant.performanceFilters[1];
      case constant.TSTL:
        return constant.performanceFilters[2];
      case constant.BTPM:
        return constant.performanceFilters[5];
      case constant.BTTL:
        return constant.performanceFilters[6];
      case "admin-owner":
        return constant.performanceFilters[0];
      case "admin-broker":
        return constant.performanceFilters[0];
      default:
        return null;
    }
  },
  performanceFilters: [
    "ADMIN",
    "PROCESS_MANAGER",
    "TEAM_LEADER",
    "SALES_AGENT",
    "BROKER_ADMIN",
    "BROKER_TELESALES_PROCESS_MANAGER",
    "BROKER_TELESALES_TEAM_LEAD",
    "BROKER_TELESALES",
  ],
  paymentDone: {
    activationComplete: {
      name: "Payment Done - Activation Complete",
      productActivated: true,
    },
    activationPending: {
      name: "Payment Done - Activation Pending",
      productActivated: false,
    },
  },
  convertddmmyyyyToDate: (date) => {
    const dateString = date.split(" ")[0];
    const dateParts = dateString.split("-");
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
  },
  convertDateToddmmyyyy: (date) => {
    const currentDate = new Date(date);
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();

    return `${day}/${month}/${year}`;
  },
  autoPopulateOwnerCallStatusFeature: true,
  autoPopulateOwnerCallStatusTimeoutFeature: true,
  autoPopulateCallStatusTimeout: 420000,
  autoPopulateCallStatusAgentsEmail: [
    "ttkpk46@gmail.com",
    "sandeepk23702@gmail.com",
  ],
  autoPopulateCallStatusCallerDisable: false,
  stringComparer: (property) => {
    return (obj1, obj2) => obj1[property].localeCompare(obj2[property]);
  },
  setQueryString: (url, args) =>
    url +
    "?" +
    Object.entries(args)
      .map(([key, value]) => key + "=" + value)
      .join("&"),
  IsJsonString: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  featuredDeveloperPackageId: 63,
  userHasRequiredRole: (userRoles, requiredRoles) => {
    if (!(userRoles && requiredRoles)) {
      return false;
    }
    return requiredRoles.some((role) => userRoles.includes(role));
  },
  checkOwnerCrmRole: (roles) =>
    Object.keys(constant.performanceMapping).filter((mappingRole) =>
      roles.find((role) => role === constant.performanceMapping[mappingRole])
    )?.[0],
  uploadListingCase: "Upload New Listing",
  noListingCase: "N/A",
  sortAlphabeticallyAscending: (a, b) => {
    if (a !== undefined && b !== undefined) {
      if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
      else if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
      else return 0;
    }
  },
  sortAlphaAscendingLabels: (a, b) => {
    if (a !== undefined && b !== undefined) {
      if (a.label?.toLowerCase() < b.label?.toLowerCase()) return -1;
      else if (a.label?.toLowerCase() > b.label?.toLowerCase()) return 1;
      else return 0;
    }
  },
  isTimestampValid: (stamp) => {
    const date = new Date(stamp);
    return Object.prototype.toString.call(date) === "[object Date]";
  },
  rmTypes: () => ({
    [constant.OOA]: "PHOENIX_AGENT",
    [constant.OFA]: "PHOENIX_FIELD_AGENT",
  }),
  childPocContactTypeId: 8,
  filterVerifiedContacts: (contacts = []) => {
    return contacts.filter(({ isVerified, contactTypeId }) => {
      if (contactTypeId === constant.childPocContactTypeId) {
        return isVerified === "Verified";
      }
      return true;
    });
  },
  packageTitleMapping: {
    SELL: {
      BASIC: {
        title: "Basic",
      },
      PREMIUM: {
        title: "Premium",
      },
      PREMIUM_PLUS: {
        title: "Premium +",
      },
      ASSISTED: {
        title: "Assist",
      },
      FIELD_ASSISTED: {
        title: "Assist +",
      },
      ASSISTED_MONEYBACK: {
        title: "Guarantee",
      },
      TRIAL_PACKAGE_1: {
        title: "Relax",
      },
      TRIAL_PACKAGE_2: {
        title: "Super Relax",
      },
    },
    RENT: {
      BASIC: {
        title: "Basic",
      },
      PREMIUM: {
        title: "Premium",
      },
      PREMIUM_PLUS: {
        title: "Premium +",
      },
      ASSISTED: {
        title: "Assist",
      },
      FIELD_ASSISTED: {
        title: "Assist +",
      },
      ASSISTED_MONEYBACK: {
        title: "Guarantee",
      },
      TRIAL_PACKAGE_1: {
        title: "Relax",
      },
      TRIAL_PACKAGE_2: {
        title: "Super Relax",
      },
    },
  },
  serviceMap: {
    SELL: "BUY",
    RENT: "RENT",
  },
  getPackageName: (packageName, service) =>
    service.charAt(0) +
    service.slice(1).toLowerCase() +
    "- " +
    constant.packageTitleMapping[service][packageName.toUpperCase()].title,
  toLocaleCurrencyString: (str) => str.toLocaleString("en-IN"),
  phoneMaskerFlag: true,
  empCityGroupMap: {
    1: {
      value: 1,
      name: "Tier 1",
      id: 1,
    },
    2: {
      value: 2,
      name: "Tier 2",
      id: 2,
    },
    3: {
      value: 3,
      name: "Tier 3",
      id: 3,
    },
  },
  getDataBucketTypeData: (timeDuration) => {
    return {
      dataBucketType: timeDuration
        ? timeDuration !== "daily"
          ? timeDuration.toUpperCase()
          : undefined
        : "WEEKLY",
      timezone: "Asia/Kolkata",
    };
  },
  delayActivationDisableTermsheet: (delayedActivationStatus) => {
    if (!delayedActivationStatus) return false;
    return !["NotRequired", "Approved"].includes(delayedActivationStatus);
  },
  telesalesOpportunityDtoType: "salesOpportunityActionCreationDto",
  telesalesOpportunityBottomCommentType: "opportunity",
  audienceMaximizerTypes: {
    locality: "locality",
    city_with_budget: "city_with_budget",
    project_level: "project_level",
    city_level: "city_level",
  },
  productTypes: {
    MG_CONVERTED: {
      label: "MG converted",
      value: 1,
    },
    DIRECT_SEED: {
      label: "Direct Seed",
      value: 2,
    },
    BULK_DEALS: {
      label: "Bulk Deals",
      value: 3,
    },
    CAMPAIGN: {
      label: "Campaign",
      value: 4,
    },
    BRAND_PRODUCT: {
      label: "Brand Product",
      value: 5,
    },
    EVENT: {
      label: "Event",
      value: 6,
    },
    PRO_LISTING: {
      label: "Pro Listing",
      value: 7,
    },
    TEST: {
      label: "Test",
      value: 8,
    },
    TRAIL: {
      label: "Trail",
      value: 9,
    },
    DEFAULT: {
      label: "Default",
      value: 10,
    },
  },
  OWNER_DASHBOARD_TILES_LOCATION: {
    LINK_SENT: "/list/linksent",
    LINK_SENT_PAYMENT_FAILED: "/list/linksentpaymentfailed",
    PAYMENT_DONE_ACTIVATION_COMPLETE: "/list/activationcomplete",
    PAYMENT_DONE_ACTIVATION_PENDING: "/list/activationpending",
  },
  disabledDispositionAgentsEmails: ["pooja232013@gmail.com"],
  capLevelTypes: ["INDIVIDUAL_CITY", "CITY", "PAN_INDIA"],
  startDateMandatoryProducts: [194, 87, 57, 181, 52, 53, 54, 58, 136],
  relaseLowerShowCaseProducts: [34, 144, 145],
  productConfigId: process.env.BUILD_ENV === "prod" ? [57, 87, 177] : [57, 87, 194],
  reassignAccountRoles: [
    "Broker Tele Sales Team Lead",
    "Broker Tele Sales Process Manager",
    "Broker Tele Sales Business Manager",
    "Builder Tele Sales Team Lead",
    "Builder Tele Sales Process Manager",
    "Builder Tele Sales Business Manager",
  ],
  NotificationRoles: [
    "CRM USER",
    "Housing Sales Manager",
    "Housing Sales Agent",
    "Builder Tele Sales",
    "MakaanSalesManager",
    "Broker Tele Sales",
  ],
  LEAD_FILTER_DATES: "leadFilterDates",
  ESCAPERECORDINGIDS: "escapeRecordingIds",
  CHECK_LSQ: "lsq",
  MORTGAGE: "mortgage",
  MORTGAGE_RENEW: "MortgageRenew",
  MORTGAGE_SOURCES: ["mortgage", "mortgagerenew"],
  actionMapping: {
    block: { action: "blocked_by_sales_agent" },
    rejected: { action: "rejected_by_rcm_agent" },
    approve: { action: "approved_by_rcm_agent" },
    retry: { action: "retry_by_sales_agent" },
    retry_bank_details: { action: "bank_details_update_retry_by_sales_agent" },
    pushbackBySalesAgent: {
      action: "mortgage_partner_from_rejected_by_rcm_to_pending_at_rcm",
    },
    pushbackByRCMAdmin: {
      action: "mortgage_partner_from_approved_by_rcm_to_pending_at_rcm",
    },
    bankUpdationApprove: { action: "bank_details_approved_by_rcm_agent" },
    bankUpdationReject: { action: "bank_details_rejected_by_rcm_agent" },
  },
  mortgageOnboardingStatus: {
    1: "PARTNER_CREATED",
    2: "IN_PROGRESS",
    3: "REFILL",
    4: "PARTNER_BLOCKED",
    5: "ONBOARDED",
  },
  mortgageOnboardingSubStatus: {
    1: "PARTNER_CREATED",
    2: "PROFESSIONAL_DETAILS_FILLED",
    3: "SIGNATORY_DETAILS_PENDING",
    4: "SIGNATORY_DETAILS_FILLED",
    5: "AGREEMENT_SIGNED",
    6: "AGREEMENT_REJECTED_RCM",
    7: "PARTNER_BLOCKED",
    8: "AGREEMENT_APPROVED",
    9: "BANK_DETAILS_FILLED_VERIFICATION_FAILED_PENNY_DROP_ALLOWED",
    10: "BANK_DETAILS_FILLED_VERIFICATION_FAILED_PENNY_DROP_NOT_ALLOWED",
    11: "BANK_DETAILS_FILLED_VERIFIED_PENNY_DROP_ALLOWED",
    12: "BANK_DETAILS_FILLED_VERIFIED_PENNY_DROP_NOT_ALLOWED",
    13: "AGREEMENT_PUSHBACK_TO_RCM",
    14: "BANK_DETAILS_UPDATE_REQUESTED",
    15: "BANK_DETAILS_UPDATE_REJECTED_BY_RCM",
    16: "BANK_DETAILS_UPDATE_APPROVED_BY_RCM",
  },
  mortgageClientTypeStatusMap: {
    1: "Developer",
    2: "Broker",
    3: "Owner",
  },
  amoebaRoles: [
    "AMOEBA_ADMIN",
    "AMOEBA_AGENT",
    "AMOEBA_MANAGER",
    "ICRM_AM_AGENT",
    "ICRM_LEAD_QUALITY",
    "ICRM_RENT_RESALE_AGENT",
    "CRM USER",
  ],
  lookupRolesToInclude: [
    "Housing Sales Manager",
    "Housing Sales Agent",
    "LOOKUP VIEW",
    "Broker Tele Sales",
    "Builder Tele Sales",
    "Housing Owner Agent",
  ],
  leadsDays: [7, 30, 90],
  mortgageRcmAdminIdList: [16898423],
  accountAgentType: "Telesales",
};

export const finalStatusOptions = [
  { propKey: "interested", label: "Interested" },
  { propKey: "details_asked_follow_up", label: "Details asked/Follow up" },
  { propKey: "call_back", label: "Call back" },
  { propKey: "not_interested", label: "Not interested" },
  { propKey: "location_mismatch", label: "Location mismatch" },
  { propKey: "budget_mismatch", label: "Budget mismatch" },
  { propKey: "call_incomplete", label: "Call Incomplete" },
  { propKey: "did_not_enquire", label: "Did not enquire" },
  { propKey: "broker", label: "Broker" },
  { propKey: "language_barrier", label: "Language barrier" },
  { propKey: "already_purchased", label: "Already Purchased" },
  { propKey: "wrong_number", label: "Wrong Number" },
  { propKey: "property_type_mismatch", label: "Property Type Mismatch" },
  { propKey: "junk_fraud_calls", label: "Junk / Fraud Calls" },
  { propKey: "city_mismatch", label: "City Mismatch" },
  { propKey: "service_mismatch", label: "Service Mismatch" },
];

export default constant;
